// MUI components
import { Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

// Template components
import MDBox from "components/MDBox";

function EditButton({ handleDashboardEditAndSave, isEditMode }) {
  return (
    <MDBox
      position="fixed"
      right="1.69em"
      bottom="1.5em"
      zIndex={99}
      sx={{ cursor: "pointer" }}
      onClick={handleDashboardEditAndSave}
    >
      <Fab color="secondary" size="medium">
        {isEditMode ? <SaveIcon fontSize="medium" /> : <EditIcon fontSize="medium" />}
      </Fab>
    </MDBox>
  );
}

export default EditButton;
