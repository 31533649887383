// Third party utilities
import { v4 as uuidv4 } from "uuid";

// NMS dashboard components
import Widget from "../widgets/Widget";

function getContainerWidgets(
  masterContainerId,
  childContainer,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  isEditMode,
  isAdmin,
  containerOrientation
) {
  const widgets = childContainer.widgetIds.map((widget, index) => (
    <Widget
      key={uuidv4()}
      index={index}
      widget={widget}
      masterContainerId={masterContainerId}
      childContainer={childContainer}
      isEditMode={isEditMode}
      isAdmin={isAdmin}
      handleSwitchWidgetType={handleSwitchWidgetType}
      handleDeleteWidget={handleDeleteWidget}
      handleBreadcrumbs={handleBreadcrumbs}
      containerOrientation={containerOrientation}
    />
  ));

  return widgets;
}

export default getContainerWidgets;
