import { useState } from "react";

// Third party utilities
import { v4 as uuidV4 } from "uuid";

import MDBox from "components/MDBox";

// NMS dashboard components
import Breadcrumbs from "../Breadcrumbs";
import CurrentDashboard from "../CurrentDashboard";

function NMSDashboard({ id, isAdmin, username, dashboardType, popupMode }) {
  // List of opened dashboards
  const [breadcrumbsList, setBreadcrumbsList] = useState([{ id, dashboardType }]);

  // Currently opened dashboard
  const currentDashboard = breadcrumbsList[breadcrumbsList.length - 1];

  // Handler to add a new dashboard to the list of opened dashboards
  const handleBreadcrumbs = (targetWidgetId, targetDashboardType) =>
    setBreadcrumbsList((prevBreadcrumbsList) => [
      ...prevBreadcrumbsList,
      { id: targetWidgetId, dashboardType: targetDashboardType },
    ]);

  return (
    <>
      {!popupMode && (
        <Breadcrumbs
          icon="home"
          title={currentDashboard.dashboardType}
          route={breadcrumbsList}
          setBreadcrumbsList={setBreadcrumbsList}
        />
      )}
      <CurrentDashboard
        key={uuidV4()}
        id={currentDashboard.id}
        username={username}
        isAdmin={isAdmin}
        popupMode={popupMode}
        handleBreadcrumbs={handleBreadcrumbs}
      />
    </>
  );
}

export default NMSDashboard;
