// Third party utilities
import { v4 as uuidv4 } from "uuid";

// Third party library components
import { Draggable } from "@hello-pangea/dnd";

// MUI components
import { Card, Icon, useMediaQuery } from "@mui/material";

// Template components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NMS dashboard configs
import { DUMMY_WIDGET_LABELS, WIDGET_EMPTY_LABEL } from "../../../configs/widgetsConfigs";
import { CONTAINER_ORIENTATION } from "../../../configs/containersConfigs";
import { IFRAME_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";

// NMS dashboard components
import Dropdown from "../../Dropdown";

function Iframe({
  index,
  widgetId,
  widgetType,
  widgetConfig,
  widgetHeight,
  masterContainerId,
  childContainerId,
  isEditMode,
  isAdmin,
  handleDeleteWidget,
  containerOrientation,
}) {
  const dropdownOptions = IFRAME_DROPDOWN_LIST;

  // Widget configuration for dummy widget
  let { primaryLabel } = DUMMY_WIDGET_LABELS;
  let getDataURL = "https://idronam.com/";
  let errorIcon = (
    <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
      warningambericon
    </Icon>
  );

  // Widget configuration for actual widget
  if (widgetConfig) {
    primaryLabel = widgetConfig.primaryLabel ? widgetConfig.primaryLabel : WIDGET_EMPTY_LABEL;
    getDataURL = widgetConfig.getDataURL;
    errorIcon = null;
  }

  // Rendering the iFrame
  const renderIFrame = (
    <MDBox position="relative" p={2} height="85%">
      <MDBox
        variant="gradient"
        bgColor="info"
        color="white"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="2.5rem"
        height="2.5rem"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">crop_landscape</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDTypography ml={6} variant="h6">
          {primaryLabel}
        </MDTypography>
        {errorIcon}
        {isEditMode && isAdmin && (
          <Dropdown
            index={index}
            options={dropdownOptions}
            masterContainerId={masterContainerId}
            childContainerId={childContainerId}
            handleDeleteWidget={handleDeleteWidget}
          />
        )}
      </MDBox>
      <iframe title={primaryLabel} frameBorder="0" src={getDataURL} width="100%" height="100%" />
    </MDBox>
  );

  // Check if the screen size is medium or below
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Stylings for the widget container
  const widgetContainerStyles = {
    flexGrow: "1",
    flexBasis: "0",
    minHeight: widgetHeight,
    margin: ".5em",
    marginTop:
      index > 0 && (matches || containerOrientation === CONTAINER_ORIENTATION.vertical)
        ? "1em"
        : "0.5em",
  };
  if (containerOrientation === CONTAINER_ORIENTATION.vertical)
    widgetContainerStyles.height = widgetHeight;

  // Generating unique draggable ID for the widget to be dragged and dropped
  const draggableId = `${widgetType}/${widgetId}/${uuidv4()}`;

  // Rendering the widget
  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={widgetContainerStyles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderIFrame}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card sx={widgetContainerStyles}>{renderIFrame}</Card>
  );
}

export default Iframe;
