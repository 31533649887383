import axios from "axios";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import variables from "globals/variables";

const application = axios.create({
  baseURL: variables.api.services,
});

const telemetry = axios.create({
  baseURL: variables.api.telemetry,
});

const externalCallServer = axios.create({
  baseURL: `${window.callServer}/app/v2`,
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${AuthenticationService.getAuthenticationToken()}`;

  return request;
};

const responseHandler = (response) => response;

const errorHandler = (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    window.location = "/site/sign-out";
  }
  return Promise.reject(error);
};

application.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

application.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

externalCallServer.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

externalCallServer.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export { application, telemetry, externalCallServer };
