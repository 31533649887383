const getVariables = () => ({
  api: {
    telemetry: "https://nms-dummy-server.onrender.com",
  },
});
// if (process.env.REACT_APP_MODE === "ui-dev") {
//   return {
//     api: {
//       services: "https://api.coraltele.com/services/api/v2/",
//       // telemetry: "https://api.coraltele.com/telemetry/api/v2/",
//       telemetry: "https://nms-dummy-server.onrender.com",
//     },
//     app: {
//       services: "https://api.coraltele.com/services/app/v2/",
//       telemetry: "https://api.coraltele.com/telemetry/app/v2/",
//     },
//     base: {
//       services: "https://api.coraltele.com/services/",
//       telemetry: "https://api.coraltele.com/telemetry/",
//     },
//     webSocket: {
//       services: "wss://api.coraltele.com/services/",
//       telemetry: "wss://api.coraltele.com/telemetry/",
//     },
//     events: {
//       services: "/app/v2/",
//       telemetry: "/app/v2/",
//     },
//     sip: {
//       domain: "ucdemo.coraltele.com",
//       webRTCServer: "wss://ucdemo.coraltele.com:7443",
//     },
//     others: {
//       conferencePortal: "https://192.168.250.191",
//     },
//   };
// }
// if (process.env.REACT_APP_MODE === "ui-local") {
//   return {
//     api: {
//       services: "http://127.0.0.1:8996/api/v2/",
//       telemetry: "http://127.0.0.1:8998/api/v2/",
//     },
//     app: {
//       services: "http://127.0.0.1:8996/app/v2/",
//       telemetry: "http://127.0.0.1:8998/app/v2/",
//     },
//     base: {
//       services: "http://127.0.0.1:8996/",
//       telemetry: "http://127.0.0.1:8998/",
//     },
//     webSocket: {
//       services: "ws://127.0.0.1:8996/app/v2/",
//       telemetry: "ws://127.0.0.1:8998/app/v2/",
//     },
//     events: {
//       services: "/app/v2/",
//       telemetry: "/app/v2/",
//     },
//     sip: {
//       domain: "ucdemo.coraltele.com",
//       webRTCServer: "wss://ucdemo.coraltele.com:7443",
//     },
//     others: {
//       conferencePortal: "https://192.168.250.191",
//     },
//   };
// }
// return {
//   api: {
//     services: `${window.location.origin}/services/api/v2/`,
//     telemetry: `${window.location.origin}/telemetry/api/v2/`,
//   },
//   app: {
//     services: `${window.location.origin}/services/app/v2/`,
//     telemetry: `${window.location.origin}/telemetry/app/v2/`,
//   },
//   base: {
//     services: `${window.location.origin}/services/`,
//     telemetry: `${window.location.origin}/telemetry/`,
//   },
//   webSocket: {
//     services: `${window.location.origin.replace("http", "ws")}/services/app/v2/`,
//     telemetry: `${window.location.origin.replace("http", "ws")}/telemetry/app/v2/`,
//   },
//   events: {
//     services: "/app/v2/",
//     telemetry: "/app/v2/",
//   },
//   sip: {
//     domain: window.location.hostname,
//     webRTCServer: `${window.location.protocol.replace("http", "ws")}//${
//       window.location.hostname
//     }:7443`,
//   },
//   others: {
//     conferencePortal: window.location.origin,
//   },
// };
// };

const variables = getVariables();

export default variables;
