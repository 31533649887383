import { useState } from "react";

// Third party library components
import { Droppable } from "@hello-pangea/dnd";

// MUI components
import { Grid, useMediaQuery } from "@mui/material";

// Template custom hooks
import { useMaterialUIController } from "context";

// NMS dashboard utiLities
import getContainerWidgets from "../../utils/containerWidgets";

// NMS dashboard configs
import { CONTAINER_ORIENTATION } from "../../configs/containersConfigs";
import DRAG_AND_DROP_DIRECTIONS from "../../configs/dragAndDropConfigs";

// NMS dashboard components
import ContainerInteraction from "../../ContainerInteraction";

function Row({
  masterContainerId,
  childContainer,
  mediaQueryBreakpoint,
  handleAddWidgets,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  isEditMode,
  isAdmin,
}) {
  const [isAddWidgetsPopupOpen, setIsAddWidgetsPopupOpen] = useState(false);
  const { darkMode } = useMaterialUIController()[0];

  // Open the add widgets popup
  const handleAddWidgetsPopupOpen = () => setIsAddWidgetsPopupOpen(true);

  // Close the add widgets popup
  const handleAddWidgetsPopupClose = () => setIsAddWidgetsPopupOpen(false);

  // Check if the screen size is medium or above
  const matches = useMediaQuery((theme) => theme.breakpoints.up(mediaQueryBreakpoint));

  // Get widgets for the child container
  const widgets = getContainerWidgets(
    masterContainerId,
    childContainer,
    handleSwitchWidgetType,
    handleDeleteWidget,
    handleBreadcrumbs,
    isEditMode,
    isAdmin,
    CONTAINER_ORIENTATION.horizontal
  );

  // Generating a unique droppable ID for the child container to handle drag and drop
  const droppableId = `${masterContainerId}/${childContainer.celId}`;

  return (
    <Droppable
      droppableId={droppableId}
      direction={matches ? DRAG_AND_DROP_DIRECTIONS.horizontal : DRAG_AND_DROP_DIRECTIONS.vertical}
    >
      {(provided) => (
        <Grid
          item
          position="relative"
          container={matches}
          border={isEditMode && "3px dashed"}
          borderColor={isEditMode && (darkMode ? "secondary" : "#ccc")}
          borderRadius={3}
          minHeight={childContainer.height}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {widgets}
          {isAdmin && isEditMode && (
            <ContainerInteraction
              masterContainerId={masterContainerId}
              childContainerId={childContainer.celId}
              isAddWidgetsPopupOpen={isAddWidgetsPopupOpen}
              handleAddWidgetsPopupOpen={handleAddWidgetsPopupOpen}
              handleAddWidgetsPopupClose={handleAddWidgetsPopupClose}
              handleAddWidgets={handleAddWidgets}
            />
          )}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );
}

export default Row;
