import { useState, useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import calculateSmartAssetMarkerPosition from "../../../../utils/calculateSmartAssetMarkerPosition";
import smartTopologyMap from "../../../../assets/SmartTopologyMap.jpg";

function MapImage({
  isAdmin,
  isEditMode,
  handleAssignLocationPopupOpen,
  handleParentRefCurrent,
  bufferLeft,
  bufferTop,
}) {
  const [imageContextMenu, setImageContextMenu] = useState(null);
  const [imageContextMenuEvent, setImageContextMenuEvent] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const smartTopologyParentContainer = document.getElementById("parent");
    handleParentRefCurrent(smartTopologyParentContainer);
  }, []);

  const handleImageClick = (event) => {
    if (!imageRef.current) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateSmartAssetMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    handleAssignLocationPopupOpen(top, left);

    setImageContextMenu(null);
  };

  const handleImageContextMenu = (event) => {
    event.preventDefault();
    setImageContextMenu(
      imageContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    setImageContextMenuEvent(event);
  };
  return (
    <MDBox onContextMenu={handleImageContextMenu}>
      <MDBox
        component="img"
        position="absolute"
        width="100%"
        height="100%"
        src={smartTopologyMap}
        alt="MapImage"
        ref={imageRef}
      />
      {isAdmin && isEditMode && (
        <Menu
          open={imageContextMenu !== null}
          onClose={() => setImageContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            imageContextMenu !== null
              ? { top: imageContextMenu.mouseY, left: imageContextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => handleImageClick(imageContextMenuEvent)}>
            Assign location
          </MenuItem>
        </Menu>
      )}
    </MDBox>
  );
}

export default MapImage;
