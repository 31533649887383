import { useState } from "react";

// Third party library components
import { Droppable } from "@hello-pangea/dnd";

// MUI components
import { Grid, useMediaQuery } from "@mui/material";

// Template custom hooks
import { useMaterialUIController } from "context";

// NMS dashboard utiLities
import getContainerWidgets from "../../utils/containerWidgets";

// NMS dashboard configs
import { CONTAINER_ORIENTATION } from "../../configs/containersConfigs";

// NMS dashboard components
import ContainerInteraction from "../../ContainerInteraction";

function Column({
  masterContainerId,
  childContainer,
  handleAddWidgets,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  isEditMode,
  isAdmin,
}) {
  const [isAddWidgetsPopupOpen, setIsAddWidgetsPopupOpen] = useState(false);
  const { darkMode } = useMaterialUIController()[0];

  // Open the add widgets popup
  const handleAddWidgetsPopupOpen = () => setIsAddWidgetsPopupOpen(true);

  // Close the add widgets popup
  const handleAddWidgetsPopupClose = () => setIsAddWidgetsPopupOpen(false);

  // Check if the screen size is medium or above
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // Get widgets for the child container
  const widgets = getContainerWidgets(
    masterContainerId,
    childContainer,
    handleSwitchWidgetType,
    handleDeleteWidget,
    handleBreadcrumbs,
    isEditMode,
    isAdmin,
    CONTAINER_ORIENTATION.vertical
  );

  // Generating a unique droppable ID for the child container to handle drag and drop
  const droppableId = `${masterContainerId}/${childContainer.celId}`;

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <Grid
          item
          xs={childContainer.width.xs || 12}
          sm={childContainer.width.sm || childContainer.width.xs || 12}
          md={childContainer.width.md || childContainer.width.sm || childContainer.width.xs || 12}
          lg={
            childContainer.width.lg ||
            childContainer.width.md ||
            childContainer.width.sm ||
            childContainer.width.xs ||
            12
          }
          xl={
            childContainer.width.xl ||
            childContainer.width.lg ||
            childContainer.width.md ||
            childContainer.width.sm ||
            childContainer.width.xs ||
            12
          }
          position="relative"
          border={isEditMode && ".15em dashed"}
          borderColor={isEditMode && (darkMode ? "secondary" : "#ccc")}
          borderRadius={3}
          // minHeight={matches && childContainer.height} // Don't know why this was there in the original code
          minHeight={childContainer.height} // This is the updated code
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {widgets}
          {isAdmin && isEditMode && (
            <ContainerInteraction
              masterContainerId={masterContainerId}
              childContainerId={childContainer.celId}
              isAddWidgetsPopupOpen={isAddWidgetsPopupOpen}
              handleAddWidgetsPopupOpen={handleAddWidgetsPopupOpen}
              handleAddWidgetsPopupClose={handleAddWidgetsPopupClose}
              handleAddWidgets={handleAddWidgets}
            />
          )}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );
}

export default Column;
