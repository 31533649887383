// Template assets
import colors from "assets/theme-dark/base/colors";

export default function getGaugeSegments(min, max, thresholdLower, thresholdUpper) {
  if (thresholdLower && thresholdUpper) {
    // Create three segments if both thresholds are present
    return {
      segmentLengths: [thresholdLower - min, thresholdUpper - thresholdLower, max - thresholdUpper],
      segmentColors: [colors.success.main, colors.warning.main, colors.primary.main],
    };
  }
  if (thresholdLower) {
    // Create two segments if only lower threshold is present
    return {
      segmentLengths: [thresholdLower - min, max - thresholdLower],
      segmentColors: [colors.success.main, colors.primary.main],
    };
  }
  return {
    // Create two segments if only upper threshold is present
    segmentLengths: [thresholdUpper - min, max - thresholdUpper],
    segmentColors: [colors.success.main, colors.primary.main],
  };
}
