// Third party utilities
import { jsPDF as JsPDF } from "jspdf";
import "jspdf-autotable";

// NMS dashboard assets
import coralTelecom from "../../../../../assets/coral-telecom.png";

export default function downloadPDF(data, columns, name) {
  // Get the columns that present in the table widget
  const modifiedColumns = columns.filter((column) => !column.omit).slice(0, 12);

  // Create a new jsPDF document
  const doc = new JsPDF({ orientation: "landscape" });

  // Set the logo image
  const logoWidth = 40;
  const logoHeight = 25;
  const logoX = doc.internal.pageSize.getWidth() - logoWidth - 14;
  const logoY = 10;
  doc.addImage(coralTelecom, "PNG", logoX, logoY, logoWidth, logoHeight);

  // Set the heading
  const headingFontSize = 18;
  const headingX = 14;
  const headingY = 15;
  doc.setFontSize(headingFontSize);
  doc.text(name, headingX, headingY, { align: "left" });

  // Set the timestamp
  const timestamp = new Date().toLocaleString("en-IN");
  const timestampFontSize = 12;
  const timestampX = 14;
  const timestampY = headingY + headingFontSize;
  doc.setFontSize(timestampFontSize);
  doc.text(timestamp, timestampX, timestampY, { align: "left" });

  // Add the data to the document
  doc.autoTable({
    startY: Math.max(logoY + logoHeight + 10, timestampY + 10),
    head: [modifiedColumns.map((column) => column.name)],
    body: data,
    columns: modifiedColumns.map((column) => ({
      header: column.name,
      dataKey: column.id.split("-")[1],
    })),
  });

  // Save the PDF
  doc.save(`${name}.pdf`);
}
