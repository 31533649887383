import { WIDGET_OPTIONS, WIDGET_TYPES } from "../widgetsConfigs";

const UI_CONFIGS = [
  {
    value: WIDGET_TYPES.calendar,
    label: WIDGET_OPTIONS.calendar,
  },
  {
    value: WIDGET_TYPES.counter,
    label: WIDGET_OPTIONS.counter,
  },
  {
    value: WIDGET_TYPES.piechart,
    label: WIDGET_OPTIONS.piechart,
  },
  {
    value: WIDGET_TYPES.doughnutchart,
    label: WIDGET_OPTIONS.doughnutchart,
  },
  {
    value: WIDGET_TYPES.barchart,
    label: WIDGET_OPTIONS.barchart,
  },
  {
    value: WIDGET_TYPES.linechart,
    label: WIDGET_OPTIONS.linechart,
  },
  {
    value: WIDGET_TYPES.serverTable,
    label: WIDGET_OPTIONS.serverTable,
  },
  {
    value: WIDGET_TYPES.UITable,
    label: WIDGET_OPTIONS.UITable,
  },
  {
    value: WIDGET_TYPES.graphTopology,
    label: WIDGET_OPTIONS.graphTopology,
  },
  {
    value: WIDGET_TYPES.topology,
    label: WIDGET_OPTIONS.topology,
  },
  {
    value: WIDGET_TYPES.smartTopology,
    label: WIDGET_OPTIONS.smartTopology,
  },
  {
    value: WIDGET_TYPES.smartAsset,
    label: WIDGET_OPTIONS.smartAsset,
  },
  {
    value: WIDGET_TYPES.iFrame,
    label: WIDGET_OPTIONS.iFrame,
  },
  {
    value: WIDGET_TYPES.gauge,
    label: WIDGET_OPTIONS.gauge,
  },
];

export default UI_CONFIGS;
