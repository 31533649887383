export default function getAllSidebarsBreakpointSum(sidebars) {
  const allSidebarsBreakpointSum = {
    xl: 0,
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0,
  };

  Object.keys(sidebars).forEach((sidebar) => {
    const sidebarBreakpoints = {
      xl:
        sidebars[sidebar].width.xl ||
        sidebars[sidebar].width.lg ||
        sidebars[sidebar].width.md ||
        sidebars[sidebar].width.sm ||
        sidebars[sidebar].width.xs ||
        12,
      lg:
        sidebars[sidebar].width.lg ||
        sidebars[sidebar].width.md ||
        sidebars[sidebar].width.sm ||
        sidebars[sidebar].width.xs ||
        12,
      md:
        sidebars[sidebar].width.md ||
        sidebars[sidebar].width.sm ||
        sidebars[sidebar].width.xs ||
        12,
      sm: sidebars[sidebar].width.sm || sidebars[sidebar].width.xs || 12,
      xs: sidebars[sidebar].width.xs || 12,
    };

    allSidebarsBreakpointSum.xl += sidebarBreakpoints.xl;
    allSidebarsBreakpointSum.lg += sidebarBreakpoints.lg;
    allSidebarsBreakpointSum.md += sidebarBreakpoints.md;
    allSidebarsBreakpointSum.sm += sidebarBreakpoints.sm;
    allSidebarsBreakpointSum.xs += sidebarBreakpoints.xs;
  });

  return allSidebarsBreakpointSum;
}
