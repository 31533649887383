// MUI components
import { Fab, Icon } from "@mui/material";

// NMS Dashboard components
import AddWidgetPopup from "../AddWidgetPopup";

function ContainerInteraction({
  masterContainerId,
  childContainerId,
  isAddWidgetsPopupOpen,
  handleAddWidgetsPopupOpen,
  handleAddWidgetsPopupClose,
  handleAddWidgets,
}) {
  return (
    <>
      <Fab
        sx={{
          position: "absolute",
          right: "-1.3em",
          top: "-1.3em",
          zIndex: 50,
          width: "2.5em",
          height: "2.5em",
          boxShadow: "none",
          transform: isAddWidgetsPopupOpen ? "rotate(90deg)" : "rotate(0deg)",
        }}
        color="secondary"
        onClick={handleAddWidgetsPopupOpen}
      >
        <Icon fontSize="small" color="light">
          settings
        </Icon>
      </Fab>
      <AddWidgetPopup
        masterContainerId={masterContainerId}
        childContainerId={childContainerId}
        isAddWidgetsPopupOpen={isAddWidgetsPopupOpen}
        handleAddWidgetsPopupClose={handleAddWidgetsPopupClose}
        handleAddWidgets={handleAddWidgets}
      />
    </>
  );
}

export default ContainerInteraction;
