// Third party library components
import GaugeChart from "react-gauge-chart";

// Template assets
import colors from "assets/theme-dark/base/colors";

// Template custom hooks
import { useMaterialUIController } from "context";

// NMS dashboard utilities
import getGaugeSegments from "../../../../utils/gaugeSegments";
import getGaugeNeedleColor from "../../../../utils/gaugeNeedleColor";

function GaugeRoot({ widgetHeight, min, max, thresholdLower, thresholdUpper, needleValue }) {
  const { darkMode } = useMaterialUIController()[0];

  // Get the segments of the gauge
  const segments = getGaugeSegments(min, max, thresholdLower, thresholdUpper);

  // Needle value in percentage
  const calc = Math.abs(needleValue - min) / Math.abs(max - min);

  // Get the color of the needle
  const needleColor = getGaugeNeedleColor(needleValue, min, thresholdLower, thresholdUpper);

  // Adjust the width and height of the gauge
  const newWidth = parseInt(widgetHeight.slice(0, -2), 10) * 1.65;
  const newHeight = parseInt(widgetHeight.slice(0, -2), 10) * 0.8;

  return (
    <GaugeChart
      id="gauge-chart"
      style={{
        width: `${newWidth}vh`,
        height: `${newHeight}vh`,
        margin: "auto",
        marginTop: "1.5em",
      }}
      marginInPercent={0.02}
      percent={calc}
      arcsLength={segments.segmentLengths}
      colors={segments.segmentColors}
      arcWidth={0.3}
      arcPadding={0.02}
      cornerRadius={4}
      textColor={darkMode ? colors.light.main : colors.dark.main}
      formatTextValue={() => parseFloat(needleValue).toFixed(2)}
      needleColor={needleColor}
      needleBaseColor="#ccc"
      animate={false}
    />
  );
}

export default GaugeRoot;
