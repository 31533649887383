// Template assets
import colors from "assets/theme/base/colors";

// NMS dashboard color configuration
const chartColors = {
  gradients: {
    ...colors.gradients,
    chartColor1: {
      main: "#a8dadc",
      state: "#a8dadc",
    },
    chartColor2: {
      main: "#c9ada7",
      state: "#c9ada7",
    },
    chartColor3: {
      main: "#ff8fab",
      state: "#ff8fab",
    },
    chartColor4: {
      main: "#778da9",
      state: "#778da9",
    },
    chartColor5: {
      main: "#bdb2ff",
      state: "#bdb2ff",
    },
    chartColor6: {
      main: "#3772ff",
      state: "#3772ff",
    },
    chartColor7: {
      main: "#9f2042",
      state: "#9f2042",
    },
    chartColor8: {
      main: "#b3efb2",
      state: "#b3efb2",
    },
    chartColor9: {
      main: "#b5179e",
      state: "#b5179e",
    },
    chartColor10: {
      main: "#7cb518",
      state: "#7cb518",
    },
    chartColor11: {
      main: "#8338ec",
      state: "#8338ec",
    },
    chartColor12: {
      main: "#90a955",
      state: "#90a955",
    },
    chartColor13: {
      main: "#f15bb5",
      state: "#f15bb5",
    },
    chartColor14: {
      main: "#0f4c5c",
      state: "#0f4c5c",
    },
    chartColor15: {
      main: "#432534",
      state: "#432534",
    },
    chartColor16: {
      main: "#f25c54",
      state: "#f25c54",
    },
    chartColor17: {
      main: "#02c39a",
      state: "#02c39a",
    },
    chartColor18: {
      main: "#004e89",
      state: "#004e89",
    },
    chartColor19: {
      main: "#9d4edd",
      state: "#9d4edd",
    },
    chartColor20: {
      main: "#56ab91",
      state: "#56ab91",
    },
    chartColor21: {
      main: "#7bf1a8",
      state: "#7bf1a8",
    },
    chartColor22: {
      main: "#86bbd8",
      state: "#86bbd8",
    },
    chartColor23: {
      main: "#f4a261",
      state: "#f4a261",
    },
    chartColor24: {
      main: "#c59fc9",
      state: "#c59fc9",
    },
    chartColor25: {
      main: "#fa003f",
      state: "#fa003f",
    },
    chartColor26: {
      main: "#001f54",
      state: "#001f54",
    },
    chartColor27: {
      main: "#5e3023",
      state: "#5e3023",
    },
    chartColor28: {
      main: "#72ddf7",
      state: "#72ddf7",
    },
    chartColor29: {
      main: "#ee2677",
      state: "#ee2677",
    },
    chartColor30: {
      main: "#aaf683",
      state: "#aaf683",
    },
    chartColor31: {
      main: "#7400b8",
      state: "#7400b8",
    },
    chartColor32: {
      main: "#d0b8ac",
      state: "#d0b8ac",
    },
    chartColor33: {
      main: "#ffbd00",
      state: "#ffbd00",
    },
    chartColor34: {
      main: "#f694c1",
      state: "#f694c1",
    },
    chartColor35: {
      main: "#7ae582",
      state: "#7ae582",
    },
    chartColor36: {
      main: "#aa4465",
      state: "#aa4465",
    },
    chartColor37: {
      main: "#bcbd8b",
      state: "#bcbd8b",
    },
    chartColor38: {
      main: "#892b64",
      state: "#892b64",
    },
    chartColor39: {
      main: "#93e1d8",
      state: "#93e1d8",
    },
    chartColor40: {
      main: "#772e25",
      state: "#772e25",
    },
    chartColor41: {
      main: "#9e0059",
      state: "#9e0059",
    },
    chartColor42: {
      main: "#5d2a42",
      state: "#5d2a42",
    },
    chartColor43: {
      main: "#38b000",
      state: "#38b000",
    },
    chartColor44: {
      main: "#578787",
      state: "#578787",
    },
    chartColor45: {
      main: "#20a4f3",
      state: "#20a4f3",
    },
    chartColor46: {
      main: "#ff0a54",
      state: "#ff0a54",
    },
    chartColor47: {
      main: "#0091ad",
      state: "#0091ad",
    },
    chartColor48: {
      main: "#faa307",
      state: "#faa307",
    },
    chartColor49: {
      main: "#8ecae6",
      state: "#8ecae6",
    },
    chartColor50: {
      main: "#fdcc8c",
      state: "#fdcc8c",
    },
  },
};

export default chartColors;
