import { useState } from "react";

// MUI components
import { Icon, Menu, MenuItem } from "@mui/material";

// NMS dashboard configs
import { DROPDOWN_OPTIONS } from "../../configs/dropdownConfigs";
import { WIDGET_TYPES } from "../../configs/widgetsConfigs";

// NMS dashboard tables
import downloadPDF from "../widgetTypes/tables/ServerTable/exportTypes/pdf";
import downloadCSV from "../widgetTypes/tables/ServerTable/exportTypes/csv";

function getNewWidgetType(option) {
  let newWidgetType = null;
  switch (option) {
    case DROPDOWN_OPTIONS.piechart:
      newWidgetType = WIDGET_TYPES.piechart;
      break;
    case DROPDOWN_OPTIONS.doughnutchart:
      newWidgetType = WIDGET_TYPES.doughnutchart;
      break;
    case DROPDOWN_OPTIONS.barchart:
      newWidgetType = WIDGET_TYPES.barchart;
      break;
    case DROPDOWN_OPTIONS.linechart:
      newWidgetType = WIDGET_TYPES.linechart;
      break;
    default:
      break;
  }
  return newWidgetType;
}

function Dropdown({
  index,
  options,
  masterContainerId,
  childContainerId,
  handleSwitchWidgetType,
  handleDeleteWidget,
  tableRecords,
  tableColumnsConfig,
  tablePdfHeading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  // Open dropdown
  const handleDropdown = (event) => setAnchorEl(event.currentTarget);

  // Handle dropdown options
  const handleDropdownOption = (option) => {
    switch (option) {
      case DROPDOWN_OPTIONS.delete:
        // Delete the widget
        handleDeleteWidget(masterContainerId, childContainerId, index);
        break;
      case DROPDOWN_OPTIONS.exportToCSV:
        // Download CSV file for the table widget
        downloadCSV(tableRecords, tablePdfHeading);
        break;
      case DROPDOWN_OPTIONS.exportToPDF:
        // Download PDF file for the table widget
        downloadPDF(tableRecords, tableColumnsConfig, tablePdfHeading);
        break;
      default: {
        // switch widget type in case of chart widgets
        const newWidgetType = getNewWidgetType(option);
        handleSwitchWidgetType(masterContainerId, childContainerId, index, newWidgetType);
        break;
      }
    }

    setAnchorEl(null);
  };

  return (
    <>
      <Icon
        color="secondary"
        fontSize="default"
        sx={{ cursor: "pointer", fontWeight: "bold" }}
        onClick={handleDropdown}
      >
        more_vert
      </Icon>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleDropdownOption(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default Dropdown;
