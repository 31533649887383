import { useState, useMemo } from "react";

// Third party utilities
import { v4 as uuidv4 } from "uuid";

// Third party library components
import { Draggable } from "@hello-pangea/dnd";

// MUI components
import { Card, CircularProgress, Icon, useMediaQuery } from "@mui/material";

// Template components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NMS dashboard RTK-Query hooks
import { useGetWidgetDataQuery } from "features/apiSlice";

// NMS dashboard configs
import { COUNTER_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import {
  DUMMY_WIDGET_LABELS,
  WIDGET_LOADING_LABEL,
  WIDGET_EMPTY_LABEL,
  WIDGET_ERROR_LABEL,
} from "../../../configs/widgetsConfigs";
import { CONTAINER_ORIENTATION } from "../../../configs/containersConfigs";
import COUNTER_DUMMY_DATA from "../../../data/counterData";

// NMS dashboard logs
import requestErrorLog from "../../../logs/error";

// NMS dashboard components
import Dropdown from "../../Dropdown";
import ExportPDF from "../../ExportPDF";

function Counter({
  index,
  widgetId,
  widgetType,
  widgetConfig,
  widgetHeight,
  masterContainerId,
  childContainerId,
  isEditMode,
  isAdmin,
  handleDeleteWidget,
  containerOrientation,
}) {
  const [exportPDFContextMenu, setExportPDFContextMenu] = useState(null);

  // Handler for context menu for the widget
  const handleExportPDFContextMenu = (event) => {
    event.preventDefault();
    setExportPDFContextMenu(
      exportPDFContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  // Handler for closing context menu for the widget
  const handleExportPDFContextMenuClose = () => setExportPDFContextMenu(null);

  // Widget configuration for dummy widget
  let getDataURL = null;
  let refreshInterval = 0;

  // Widget configuration for actual widget
  if (widgetConfig) {
    getDataURL = widgetConfig.getDataURL;
    refreshInterval = widgetConfig.refreshInterval ? widgetConfig.refreshInterval : 0;
  }

  // Fetching data for the widget
  const { data, isError, error } = useGetWidgetDataQuery(getDataURL, {
    pollingInterval: refreshInterval,
    refetchOnReconnect: true,
    skip: !getDataURL,
  });

  // If error, return error message
  useMemo(() => requestErrorLog(isError, error, widgetId), [error]);

  // Rendering logic for the widget
  let primaryLabel = null;
  let count = null;
  let errorIcon = null;
  const dropdownOptions = COUNTER_DROPDOWN_LIST;

  // If actual widget is being rendered
  if (widgetConfig) {
    if (error) {
      // If request timeout occurs or server responds with an error
      primaryLabel = WIDGET_ERROR_LABEL;
      count = "";
      errorIcon = (
        <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
    } else if (data) {
      // If data is available for the actual widget
      primaryLabel = widgetConfig.primaryLabel ? widgetConfig.primaryLabel : WIDGET_EMPTY_LABEL;
      count = data[widgetConfig.valueField];
    } else {
      // If data is currently being fetched for the actual widget
      primaryLabel = WIDGET_LOADING_LABEL;
      count = "";
      errorIcon = (
        <MDBox sx={{ marginLeft: "auto", color: "red" }}>
          <CircularProgress color="white" size={20} />
        </MDBox>
      );
    }
  } else {
    // If dummy widget is being rendered
    primaryLabel = DUMMY_WIDGET_LABELS.secondaryLabel;
    count = COUNTER_DUMMY_DATA;
    errorIcon = (
      <Icon sx={{ color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  // Rendering the counter
  const renderCounter = (
    <MDBox p={2} position="relative">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">leaderboard</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" width="100%">
        <MDBox ml={6} mr={widgetConfig && (error || !data) ? "auto" : 1} textAlign="right">
          <MDTypography variant={!widgetConfig || (!error && data) ? "h3" : "h6"}>
            {primaryLabel}
          </MDTypography>
          <MDTypography variant="h3" color="text">
            {useMemo(() => count, [data, error])}
          </MDTypography>
        </MDBox>
        {errorIcon}
        {isEditMode && isAdmin && (
          <Dropdown
            index={index}
            options={dropdownOptions}
            masterContainerId={masterContainerId}
            childContainerId={childContainerId}
            handleDeleteWidget={handleDeleteWidget}
          />
        )}
      </MDBox>
    </MDBox>
  );

  // Check if the screen size is medium or below
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Stylings for the widget container
  const widgetContainerStyles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop:
      index > 0 && (matches || containerOrientation === CONTAINER_ORIENTATION.vertical)
        ? "1em"
        : "0.5em",
  };
  if (containerOrientation === CONTAINER_ORIENTATION.vertical)
    widgetContainerStyles.height = widgetHeight;

  // Generating unique draggable ID for the widget to be dragged and dropped
  const draggableId = `${widgetType}/${widgetId}/${uuidv4()}`;

  // Generating unique ID for the widget component in order for PDF export to work
  const widgetComponentId = `${primaryLabel}/${childContainerId}`;

  // Rendering the widget
  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={widgetContainerStyles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderCounter}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      id={widgetComponentId}
      onContextMenu={handleExportPDFContextMenu}
      sx={widgetContainerStyles}
    >
      {renderCounter}
      {widgetConfig && (
        <ExportPDF
          exportComponentId={widgetComponentId}
          exportPDFContextMenu={exportPDFContextMenu}
          handleExportPDFContextMenuClose={handleExportPDFContextMenuClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Counter;
