// NMS dashboard utilities
import chartsActionHandle from "../../../../../utils/chartsActionHandle";

// NMS dashboard configs
import chartColors from "../../chartColors";

function configs(
  sectorLabels,
  data,
  cutout,
  showLegends,
  actions,
  setPopUpOpen,
  setPopUpDashboardId,
  handleBreadcrumbs
) {
  const { gradients } = chartColors;

  // Define the colors of the sectors
  const sectorColors = [];
  data.sectorColors.forEach((color) => {
    if (color === "info") sectorColors.push(gradients.info.main);
    else sectorColors.push(gradients[color].state);
  });

  return {
    roundchartData: {
      labels: sectorLabels,
      datasets: [
        {
          label: data.label,
          weight: 9,
          cutout,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 0,
          backgroundColor: sectorColors,
          fill: false,
          data: data.sectorValues,
        },
      ],
    },
    roundchartOptions: {
      onClick: (event, element) =>
        chartsActionHandle(
          element,
          actions,
          sectorLabels,
          setPopUpOpen,
          setPopUpDashboardId,
          handleBreadcrumbs
        ),
      animation: {
        tension: {
          easing: "linear",
          from: 1,
          to: 0,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          display: showLegends,
          labels: {
            boxWidth: 15,
            boxHeight: 15,
            generateLabels(chart) {
              const { labels } = chart.data;
              return labels.map((label, i) => ({
                text: `${chart.data.datasets[0].data[i]}: ${label !== null ? label : "Other"}`,
                fillStyle: chart.data.datasets[0].backgroundColor[i],
                strokeStyle: chart.data.datasets[0].backgroundColor[i],
                lineWidth: 1,
              }));
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
