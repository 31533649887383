import { useState, useMemo } from "react";

// Third party utilities
import { v4 as uuidv4 } from "uuid";

// Third party library components
import { Draggable } from "@hello-pangea/dnd";
import DataTable, { createTheme } from "react-data-table-component";

// MUI components
import { Card, CircularProgress, Icon, useMediaQuery } from "@mui/material";

// Template assets
import colors from "assets/theme-dark/base/colors";

// Template custom hooks
import { useMaterialUIController } from "context";

// Template components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// NMS dashboard RTK-Query hooks
import { useGetDataQuery } from "features/apiSlice";

// NMS dashboard utiLities
import getFilteredRecords from "../../../../utils/filteredRecords";
import tableActionHandle from "../../../../utils/tableActionHandle";

// NMS dashboard configs
import { TABLE_DROPDOWN_LIST } from "../../../../configs/dropdownConfigs";
import {
  DUMMY_WIDGET_LABELS,
  WIDGET_LOADING_LABEL,
  WIDGET_EMPTY_LABEL,
  WIDGET_ERROR_LABEL,
} from "../../../../configs/widgetsConfigs";
import { CONTAINER_ORIENTATION } from "../../../../configs/containersConfigs";
import { TABLE_DUMMY_DATA, TABLE_DUMMY_COLUMNS } from "../../../../data/tableData";
import getColumnsConfig from "./configs/columnsConfig";
import "./css/index.css";

// NMS dashboard logs
import requestErrorLog from "../../../../logs/error";

// NMS dashboard components
import PopUp from "../../../../PopUp";
import Dropdown from "../../../Dropdown";

function UITable({
  index,
  widgetId,
  widgetType,
  widgetConfig,
  widgetHeight,
  masterContainerId,
  childContainerId,
  isEditMode,
  isAdmin,
  handleDeleteWidget,
  handleBreadcrumbs,
  containerOrientation,
}) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpDashboardId, setPopUpDashboardId] = useState(null);
  const [filterText, setFilterText] = useState("");
  const { darkMode } = useMaterialUIController()[0];

  // Widget configuration for dummy widget
  let getDataURL = null;
  let refreshInterval = 0;
  let actions = [];
  let columns = TABLE_DUMMY_COLUMNS;

  // Widget configuration for actual widget
  if (widgetConfig) {
    getDataURL = widgetConfig.getDataURL;
    refreshInterval = widgetConfig.refreshInterval ? widgetConfig.refreshInterval : 0;
    actions = widgetConfig.actions ? widgetConfig.actions : [];
    columns = widgetConfig.columns ? widgetConfig.columns : [];
  }

  // Columns configuration for the table widget
  const columnsConfiguration = getColumnsConfig(columns);

  // Fetching data for the widget
  const { data, isError, error } = useGetDataQuery(getDataURL, {
    pollingInterval: refreshInterval,
    refetchOnReconnect: true,
    skip: !getDataURL,
  });

  // If error, return error message
  useMemo(() => requestErrorLog(isError, error, widgetId), [error]);

  // Structuring UI table data on the basis of columns configuration
  let UITableData = null;
  if (data)
    UITableData = data.map((record) => {
      const newRecord = {};
      Object.keys(record).forEach((field) => {
        const column = columns.filter((col) => col.field === field)[0];

        if (column && column.type === "severity") {
          if (!record[field] || record[field] === 0) newRecord[field] = "🟢";
          else if (record[field] === 1) newRecord[field] = "🟡";
          else if (record[field] === 2) newRecord[field] = "🟠";
          else if (record[field] === 3) newRecord[field] = "🔴";
          else newRecord[field] = "🟤";
        } else if (column && column.type === "epoch") {
          const date = new Date(record[field] * 1000);
          newRecord[field] = date.toLocaleString("en-IN");
        } else if (field === "status") newRecord[field] = record[field] ? "Active" : "Inactive";
        else if (!record[field]) newRecord[field] = "NULL";
        else newRecord[field] = record[field];
      });

      return newRecord;
    });
  else UITableData = TABLE_DUMMY_DATA;

  // Rendering logic for the widget
  let primaryLabel = null;
  let filteredRecords = null;
  let errorIcon = null;
  let dropdownOptions = [];
  if (widgetConfig) {
    if (error) {
      // If request timeout occurs or server responds with an error
      primaryLabel = WIDGET_ERROR_LABEL;
      filteredRecords = [];
      errorIcon = (
        <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
      if (isAdmin)
        dropdownOptions = isEditMode
          ? TABLE_DROPDOWN_LIST.slice(TABLE_DROPDOWN_LIST.length - 1)
          : [];
      else dropdownOptions = [];
    } else if (data) {
      // If data is available for the actual widget
      primaryLabel = widgetConfig.primaryLabel ? widgetConfig.primaryLabel : WIDGET_EMPTY_LABEL;
      filteredRecords = getFilteredRecords(UITableData, columns, filterText);
      if (isAdmin)
        dropdownOptions = isEditMode ? TABLE_DROPDOWN_LIST : TABLE_DROPDOWN_LIST.slice(0, -1);
      else dropdownOptions = TABLE_DROPDOWN_LIST.slice(0, -1);
    } else {
      // If data is currently being fetched for the actual widget
      primaryLabel = WIDGET_LOADING_LABEL;
      filteredRecords = [];
      errorIcon = (
        <MDBox sx={{ marginLeft: "auto", color: "red" }}>
          <CircularProgress color="white" size={20} />
        </MDBox>
      );
      if (isAdmin)
        dropdownOptions = isEditMode
          ? TABLE_DROPDOWN_LIST.slice(TABLE_DROPDOWN_LIST.length - 1)
          : [];
      else dropdownOptions = [];
    }
  } else {
    // If dummy widget is being rendered
    primaryLabel = DUMMY_WIDGET_LABELS.primaryLabel;
    filteredRecords = getFilteredRecords(UITableData, columns, filterText);
    errorIcon = (
      <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
    if (isAdmin)
      dropdownOptions = isEditMode ? TABLE_DROPDOWN_LIST : TABLE_DROPDOWN_LIST.slice(0, -1);
    else dropdownOptions = TABLE_DROPDOWN_LIST.slice(0, -1);
  }

  // Creating custom dark theme for the UI table widget
  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark"
  );

  // Sub header for the UI table widget
  const tableSubHeader = useMemo(() => {
    // Handler for filtering the table data
    const handleFilterChange = (event) => {
      setFilterText(() => {
        let tableFilterText = event.target.value.toLowerCase();

        switch (tableFilterText) {
          case "green":
            tableFilterText = "🟢";
            break;
          case "yellow":
            tableFilterText = "🟡";
            break;
          case "orange":
            tableFilterText = "🟠";
            break;
          case "red":
            tableFilterText = "🔴";
            break;
          case "brown":
          case "grey":
            tableFilterText = "🟤";
            break;
          default:
            break;
        }

        return tableFilterText;
      });
    };

    return (
      <MDInput
        type="search"
        label="Search records"
        value={filterText}
        onChange={handleFilterChange}
      />
    );
  }, [filterText, error]);

  // Dropdown for the UI table widget
  const tableActions = useMemo(
    () => (
      <MDBox>
        {errorIcon}
        {dropdownOptions.length > 0 && (
          <Dropdown
            index={index}
            options={dropdownOptions}
            masterContainerId={masterContainerId}
            childContainerId={childContainerId}
            handleDeleteWidget={handleDeleteWidget}
            tableRecords={filteredRecords}
            tableColumnsConfig={columnsConfiguration}
            tablePdfHeading={primaryLabel}
          />
        )}
      </MDBox>
    ),
    [filteredRecords, error]
  );

  // Rendering the data table
  const dataTable = useMemo(
    () => (
      <MDBox p={(data || !widgetConfig) && !error ? "" : 2} position="relative">
        <MDBox
          width="2.5rem"
          height="2.5rem"
          bgColor="info"
          variant="gradient"
          coloredShadow="info"
          borderRadius="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
          position="absolute"
          top={(data || !widgetConfig) && !error ? "-1.2em" : "-.7em"}
          left={(data || !widgetConfig) && !error && ".3em"}
          zIndex={(data || !widgetConfig) && !error && "99"}
        >
          <Icon fontSize="small">view_list</Icon>
        </MDBox>
        {(data || !widgetConfig) && !error ? (
          <>
            <DataTable
              keyField={uuidv4()}
              data={filteredRecords}
              columns={columnsConfiguration}
              onRowClicked={(row) =>
                tableActionHandle(
                  row,
                  actions,
                  setPopUpOpen,
                  setPopUpDashboardId,
                  handleBreadcrumbs
                )
              }
              responsive
              pagination
              paginationPerPage={[5]}
              striped
              theme={darkMode ? "modifiedDarkTheme" : "default"}
              title={<MDTypography variant="h6">{primaryLabel}</MDTypography>}
              defaultSortFieldId="id"
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
              fixedHeader
              fixedHeaderScrollHeight={widgetHeight || "300px"}
              actions={tableActions}
              subHeader={(data || !widgetConfig) && !error}
              subHeaderComponent={tableSubHeader}
              persistTableHead={(data || !widgetConfig) && !error}
            />
            {widgetConfig && (
              <PopUp
                open={popUpOpen}
                isAdmin={isAdmin}
                handleClose={() => setPopUpOpen(false)}
                dashboardId={popUpDashboardId}
              />
            )}
          </>
        ) : (
          <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
            <MDTypography ml={6} variant="h6">
              {primaryLabel}
            </MDTypography>
            {errorIcon}
            {dropdownOptions.length > 0 && (
              <Dropdown
                index={index}
                options={dropdownOptions}
                masterContainerId={masterContainerId}
                childContainerId={childContainerId}
                handleDeleteWidget={handleDeleteWidget}
              />
            )}
          </MDBox>
        )}
      </MDBox>
    ),
    [data, error, popUpOpen, filterText]
  );

  // Check if the screen size is medium or below
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Stylings for the widget container
  const widgetContainerStyles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop:
      index > 0 && (matches || containerOrientation === CONTAINER_ORIENTATION.vertical)
        ? "1em"
        : "0.5em",
    padding: (data || !widgetConfig) && !error && "0.5em",
  };

  // Generating unique draggable ID for the widget to be dragged and dropped
  const draggableId = `${widgetType}/${widgetId}/${uuidv4()}`;

  // Rendering the widget
  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={widgetContainerStyles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {dataTable}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card sx={widgetContainerStyles}>{dataTable}</Card>
  );
}

export default UITable;
