import { useMemo } from "react";

// Third party utilities
import { v4 as uuidv4 } from "uuid";

// MUI components
import { Backdrop, CircularProgress } from "@mui/material";

// Template components
import MDBox from "components/MDBox";

// NMS dashboard RTK-Query hooks
import { useGetLayoutQuery } from "features/apiSlice";

// NMS dashboard utilities
import singleContainerLayout from "../utils/singleContainerLayout";

// NMS dashboard logs
import requestErrorLog from "../logs/error";

// NMS dashboard components
import Layout from "../Layout";

function CurrentDashboard({ id, username, isAdmin, popupMode, handleBreadcrumbs }) {
  // Fetching the dashboard layout
  const { data, isError, error } = useGetLayoutQuery(`/dashboard/dashboard/${id}`, {
    refetchOnReconnect: true,
    pollingInterval: 2000,
  });

  // If error, return error message
  useMemo(() => requestErrorLog(isError, error, id), [error]);

  // Rendering logic for the dashboard
  let layout = null;
  let allMasterContainersEmpty = true;
  let dashboard = null;

  if (error) {
    // If request timeout occurs or server responds with an error
    dashboard = null;
  } else if (data) {
    // If layout is available for the dashboard
    const { customLayout, defaultLayout } = data;
    layout = data.customLayout ? customLayout : defaultLayout;

    // Switch to single container layout if all master containers of layout are empty
    Object.keys(layout).forEach((masterContainerId) => {
      if (layout[masterContainerId].length > 0) allMasterContainersEmpty = false;
    });

    if (allMasterContainersEmpty) layout = singleContainerLayout(id);

    dashboard = (
      <Layout
        key={uuidv4()}
        dashboardId={id}
        layout={layout}
        username={username}
        isAdmin={isAdmin}
        popupMode={popupMode}
        handleBreadcrumbs={handleBreadcrumbs}
      />
    );
  } else {
    // If layout is not available for the dashboard
    dashboard = (
      <MDBox sx={{ overflow: "hidden" }}>
        <Backdrop
          open
          invisible
          sx={{
            height: "100vh",
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="info" />
        </Backdrop>
      </MDBox>
    );
  }

  return useMemo(() => dashboard, [data, error]);
}

export default CurrentDashboard;

// Backup code!!!
// // MUI components
// import { CircularProgress } from "@mui/material";

// // Template components
// import MDBox from "components/MDBox";

// // NMS dashboard RTK-Query hooks
// import { useGetLayoutQuery } from "features/apiSlice";

// // NMS dashboard utilities
// import singleContainerLayout from "../utils/singleContainerLayout";

// // NMS dashboard components
// import Layout from "../Layout";

// function CurrentDashboard({ id, username, isAdmin, popupMode, handleBreadcrumbs }) {
//   // Fetching layout from dashboard API
//   const { data, error } = useGetLayoutQuery(`/dashboard/dashboard/${id}`, {
//     refetchOnReconnect: true,
//   });

//   // If error, return error message
//   if (error) {
//     console.log(`Server responded with ${error.status || error} status`);
//     console.log(`Unable to fetch layout for dashboard widget ID: ${id}`);
//     console.log("Please contact the server administrator");
//     return null;
//   }

//   // If no error, return layout
//   let layout = null;
//   let allMasterContainersEmpty = true;
//   if (data) {
//     const { customLayout, defaultLayout } = data;
//     layout = data.customLayout ? customLayout : defaultLayout;

//     // Switch to single container layout if all master containers of layout are empty
//     Object.keys(layout).forEach((masterContainerId) => {
//       if (layout[masterContainerId].length > 0) allMasterContainersEmpty = false;
//     });

//     if (allMasterContainersEmpty) layout = singleContainerLayout(id);
//   }

//   return layout ? (
//     <Layout
//       dashboardId={id}
//       layout={layout}
//       username={username}
//       isAdmin={isAdmin}
//       popupMode={popupMode}
//       handleBreadcrumbs={handleBreadcrumbs}
//     />
//   ) : (
//     <MDBox display="flex" justifyContent="center" mt="20%">
//       <CircularProgress disableShrink color="info" />
//     </MDBox>
//   );
// }

// export default CurrentDashboard;
