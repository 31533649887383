import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Tooltip({ location, active, inactive }) {
  return (
    <MDBox
      position="absolute"
      zIndex="99"
      sx={(theme) => ({ backgroundColor: theme.palette.white.main })}
      paddingTop="0.1em"
      paddingInline="0.5em"
      borderRadius="md"
      left={location.xcoordinate - 50}
      top={location.ycoordinate - 70}
    >
      <MDTypography fontWeight="medium" sx={{ fontSize: "1rem", color: "#344767" }}>
        {location.configuration.labelText || location.name}
      </MDTypography>
      <MDBox display="flex">
        <MDBox width=".5em" height=".5em" sx={{ backgroundColor: "red", borderRadius: "50%" }} />
        <MDTypography
          mt={-1}
          ml={1}
          fontWeight="medium"
          sx={{ fontSize: "1rem", color: "#344767" }}
        >
          {inactive}
        </MDTypography>
      </MDBox>
      <MDBox display="flex">
        <MDBox width=".5em" height=".5em" sx={{ backgroundColor: "green", borderRadius: "50%" }} />
        <MDTypography
          mt={-1}
          ml={1}
          fontWeight="medium"
          sx={{ fontSize: "1rem", color: "#344767" }}
        >
          {active}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Tooltip;
