// MUI components
import { CircularProgress, Chip, Autocomplete, TextField } from "@mui/material";

function RealWidgetAutocomplete({
  isRealWidgetAutocompleteOpen,
  handleOpenRealWidgetAutocomplete,
  chosenRealWidgets,
  handleChosenRealWidgets,
  isDarkMode,
  data,
}) {
  return (
    <Autocomplete
      onOpen={handleOpenRealWidgetAutocomplete}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => `${option.label}-${option.value}`}
      multiple
      autoComplete
      filterSelectedOptions
      onChange={handleChosenRealWidgets}
      options={
        data
          ? data.map((widget) => ({
              label: widget.id,
              value: widget.name,
            }))
          : []
      }
      loading={!data}
      loadingText="Loading widgets..."
      renderTags={(value, getTagProps) =>
        chosenRealWidgets.map((option, index) => (
          <Chip
            variant="outlined"
            label={`${option.label}-${option.value}`}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          color={isDarkMode ? "white" : "black"}
          sx={(theme) => ({
            marginTop: ".5em",
            backgroundColor: isDarkMode ? theme.palette.dark.main : theme.palette.white.main,
            "& input::placeholder": {
              color: isDarkMode ? theme.palette.text.main : "",
            },
          })}
          {...params}
          variant="filled"
          placeholder="Please select widget ID"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isRealWidgetAutocompleteOpen && !data ? (
                  <CircularProgress color="info" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default RealWidgetAutocomplete;
