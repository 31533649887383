export default function getColumnsConfig(columns) {
  return columns.map((column, index) => ({
    id: `${index}-${column.field}` || `${index}-Dummy field`,
    name: column.heading || "Dummy heading",
    grow: column.heading.length,
    wrap: true,
    sortable: true,
    reorder: true,
    omit: !column.display,
    selector: (row) => row[column.field],
  }));
}
