export default function getFilteredRecords(data, columns, filterText) {
  // lowercase the filter text
  const lowerCasedFilterText = filterText.toLowerCase();

  // Return the union of the filtered items for all columns
  let filteredRecords = [];
  columns.forEach((column) => {
    const currentFilteredRecords = data.filter(
      (record) =>
        column.display &&
        record[column.field] &&
        record[column.field].toString().toLowerCase().startsWith(lowerCasedFilterText)
    );
    if (currentFilteredRecords.length > filteredRecords.length)
      filteredRecords = currentFilteredRecords;
  });

  return filteredRecords;
}
