import { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { Draggable } from "@hello-pangea/dnd";
import { Card, Icon, CircularProgress, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetTopologyWidgetDataQuery } from "features/apiSlice";
import { SMART_TOPOLOGY_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import {
  DUMMY_WIDGET_LABELS,
  WIDGET_LOADING_LABEL,
  WIDGET_EMPTY_LABEL,
  WIDGET_ERROR_LABEL,
} from "../../../configs/widgetsConfigs";
import { CONTAINER_ORIENTATION } from "../../../configs/containersConfigs";
import TOPOLOGY_DUMMY_DATA from "../../../data/topologyData";
import getAggregatedTree from "../../../utils/nodeCount";
import requestErrorLog from "../../../logs/error";
import SmartTopologyRoot from "./smartTopologyRoot";
import ExportPDF from "../../ExportPDF";
import Dropdown from "../../Dropdown";

function SmartTopology({
  index,
  widgetId,
  widgetType,
  widgetConfig,
  widgetHeight,
  masterContainerId,
  childContainerId,
  isEditMode,
  isAdmin,
  handleDeleteWidget,
  containerOrientation,
}) {
  const [exportPDFContextMenu, setExportPDFContextMenu] = useState(null);
  const [parentRefCurrent, setParentRefCurrent] = useState(null);

  const handleExportPDFContextMenu = (event) => {
    event.preventDefault();
    setExportPDFContextMenu(
      exportPDFContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleExportPDFContextMenuClose = () => setExportPDFContextMenu(null);

  const handleParentRefCurrent = (current) => setParentRefCurrent(current);

  let getDataURL = null;
  let refreshInterval = null;

  if (widgetConfig) {
    getDataURL = widgetConfig.getDataURL;
    refreshInterval = widgetConfig.refreshInterval ? widgetConfig.refreshInterval : 0;
  }

  const { data, isError, error } = useGetTopologyWidgetDataQuery(getDataURL, {
    pollingInterval: refreshInterval,
    refetchOnReconnect: true,
    skip: !getDataURL,
  });

  useMemo(() => requestErrorLog(isError, error, widgetId), [error]);

  let primaryLabel = null;
  let secondaryLabel = null;
  let smartTopologyData = null;
  let aggregatedTree = null;
  let errorIcon = null;
  const dropdownOptions = SMART_TOPOLOGY_DROPDOWN_LIST;

  if (widgetConfig) {
    if (error) {
      primaryLabel = WIDGET_ERROR_LABEL;
      secondaryLabel = WIDGET_EMPTY_LABEL;
      smartTopologyData = [];
      aggregatedTree = [];
      errorIcon = (
        <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
    } else if (data) {
      primaryLabel = widgetConfig.primaryLabel ? widgetConfig.primaryLabel : WIDGET_EMPTY_LABEL;
      secondaryLabel = widgetConfig.secondaryLabel
        ? widgetConfig.secondaryLabel
        : WIDGET_EMPTY_LABEL;
      smartTopologyData = data;
      aggregatedTree = getAggregatedTree(data);
    } else {
      primaryLabel = WIDGET_LOADING_LABEL;
      secondaryLabel = WIDGET_EMPTY_LABEL;
      smartTopologyData = [];
      aggregatedTree = [];
      errorIcon = (
        <MDBox sx={{ marginLeft: "auto", color: "red" }}>
          <CircularProgress color="white" size={20} />
        </MDBox>
      );
    }
  } else {
    primaryLabel = DUMMY_WIDGET_LABELS.primaryLabel;
    secondaryLabel = DUMMY_WIDGET_LABELS.secondaryLabel;
    smartTopologyData = TOPOLOGY_DUMMY_DATA;
    aggregatedTree = getAggregatedTree(TOPOLOGY_DUMMY_DATA);
    errorIcon = (
      <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  const smartTopologyContainer = (
    <MDBox p={2} position="relative">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">hub</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {errorIcon}
        {isEditMode && isAdmin && (
          <Dropdown
            index={index}
            options={dropdownOptions}
            masterContainerId={masterContainerId}
            childContainerId={childContainerId}
            handleDeleteWidget={handleDeleteWidget}
          />
        )}
      </MDBox>
      {aggregatedTree.length > 0 && (
        <MDBox id="parent">
          <SmartTopologyRoot
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            smartTopologyData={smartTopologyData}
            aggregatedTree={aggregatedTree}
            parentRefCurrent={parentRefCurrent}
            handleParentRefCurrent={handleParentRefCurrent}
            widgetConfig={widgetConfig}
            getDataURL={getDataURL}
          />
        </MDBox>
      )}
    </MDBox>
  );

  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const widgetContainerStyles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop:
      index > 0 && (matches || containerOrientation === CONTAINER_ORIENTATION.vertical)
        ? "1em"
        : "0.5em",
  };

  const draggableId = `${widgetType}/${widgetId}/${uuidv4()}`;

  const widgetComponentId = `${primaryLabel}/${childContainerId}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={widgetContainerStyles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {smartTopologyContainer}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={widgetContainerStyles}
      onContextMenu={handleExportPDFContextMenu}
      id={widgetComponentId}
    >
      {smartTopologyContainer}
      {widgetConfig && (
        <ExportPDF
          exportComponentId={widgetComponentId}
          exportPDFContextMenu={exportPDFContextMenu}
          handleExportPDFContextMenuClose={handleExportPDFContextMenuClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default SmartTopology;
