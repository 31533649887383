import { useState, useMemo } from "react";

// Third party utilities
import { v4 as uuidv4 } from "uuid";

// Third party library components
import { Draggable } from "@hello-pangea/dnd";

// MUI components
import { Card, CircularProgress, Icon, useMediaQuery } from "@mui/material";

// Template components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NMS dashboard RTK-Query hooks
import { useGetWidgetDataQuery } from "features/apiSlice";

// NMS dashboard configs
import {
  DUMMY_WIDGET_LABELS,
  WIDGET_LOADING_LABEL,
  WIDGET_EMPTY_LABEL,
  WIDGET_ERROR_LABEL,
} from "../../../configs/widgetsConfigs";
import { CONTAINER_ORIENTATION } from "../../../configs/containersConfigs";
import { CALENDAR_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import CALENDAR_DUMMY_DATA from "../../../data/calendarData";

// NMS dashboard logs
import requestErrorLog from "../../../logs/error";

// NMS dashboard components
import Dropdown from "../../Dropdown";
import CustomDatePicker from "./CustomDatePicker";
import NivoCalendar from "./NivoCalendar";
import ExportPDF from "../../ExportPDF";

function Calendar({
  index,
  widgetId,
  widgetType,
  widgetConfig,
  widgetHeight,
  masterContainerId,
  childContainerId,
  isEditMode,
  isAdmin,
  handleDeleteWidget,
  containerOrientation,
}) {
  const [exportPDFContextMenu, setExportPDFContextMenu] = useState(null);

  // Handler for context menu for the widget
  const handleExportPDFContextMenu = (event) => {
    event.preventDefault();
    setExportPDFContextMenu(
      exportPDFContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  // Handler for closing context menu for the widget
  const handleExportPDFContextMenuClose = () => setExportPDFContextMenu(null);

  // Get the current formatted date
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const month = `0${currentDate.getMonth() < 12 ? currentDate.getMonth() + 1 : 1}`.slice(-2);
  const day = `0${currentDate.getDate()}`.slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  const [startDate, setStartDate] = useState(`${year - 1}-01-01`);
  const [endDate, setEndDate] = useState(formattedDate);

  // Widget configuration for dummy widget
  let getDataURL = null;
  let refreshInterval = 0;

  // Widget configuration for actual widget
  if (widgetConfig) {
    getDataURL = `${widgetConfig.getDataURL}/${startDate}/${endDate}`;
    refreshInterval = widgetConfig.refreshInterval ? widgetConfig.refreshInterval : 0;
  }

  // Fetching data for the widget
  const { data, isError, error } = useGetWidgetDataQuery(getDataURL, {
    pollingInterval: refreshInterval,
    refetchOnReconnect: true,
    skip: !getDataURL,
  });

  // If error, return error message
  useMemo(() => requestErrorLog(isError, error, widgetId), [error]);

  // Rendering logic for the widget
  let primaryLabel = null;
  let secondaryLabel = null;
  let calendar = null;
  let datePicker = null;
  let errorIcon = null;
  const dropdownOptions = CALENDAR_DROPDOWN_LIST;

  // If actual widget is being rendered
  if (widgetConfig) {
    if (error) {
      // If request timeout occurs or server responds with an error
      primaryLabel = WIDGET_ERROR_LABEL;
      secondaryLabel = WIDGET_EMPTY_LABEL;
      errorIcon = (
        <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
    } else if (data) {
      // If data is available for the actual widget
      primaryLabel = widgetConfig.primaryLabel ? widgetConfig.primaryLabel : WIDGET_EMPTY_LABEL;
      secondaryLabel = widgetConfig.secondaryLabel
        ? widgetConfig.secondaryLabel
        : WIDGET_EMPTY_LABEL;
      calendar = (
        <NivoCalendar
          calendarData={data}
          startDate={startDate}
          endDate={endDate}
          widgetHeight={widgetHeight}
        />
      );
      datePicker = (
        <CustomDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      );
    } else {
      // If data is currently being fetched for the actual widget
      primaryLabel = WIDGET_LOADING_LABEL;
      secondaryLabel = WIDGET_EMPTY_LABEL;
      errorIcon = (
        <MDBox sx={{ marginLeft: "auto", color: "red" }}>
          <CircularProgress color="white" size={20} />
        </MDBox>
      );
    }
  } else {
    // If dummy widget is being rendered
    primaryLabel = DUMMY_WIDGET_LABELS.primaryLabel;
    secondaryLabel = DUMMY_WIDGET_LABELS.secondaryLabel;
    calendar = (
      <NivoCalendar
        calendarData={CALENDAR_DUMMY_DATA}
        startDate={startDate}
        endDate={endDate}
        widgetHeight={widgetHeight}
      />
    );
    datePicker = (
      <CustomDatePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    );
    errorIcon = (
      <Icon sx={{ marginLeft: ".5em", color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  // Rendering the calendar
  const renderCalendar = (
    <MDBox p={2} position="relative" height={!error && data && parseInt(widgetHeight, 10) * 12}>
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">appsoutage</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={1}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {datePicker}
        {errorIcon}
        {isEditMode && isAdmin && (
          <Dropdown
            index={index}
            options={dropdownOptions}
            masterContainerId={masterContainerId}
            childContainerId={childContainerId}
            handleDeleteWidget={handleDeleteWidget}
          />
        )}
      </MDBox>
      {useMemo(() => calendar, [data, error, startDate, endDate])}
    </MDBox>
  );

  // Check if the screen size is medium or below
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Stylings for the widget container
  const widgetContainerStyles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop:
      index > 0 && (matches || containerOrientation === CONTAINER_ORIENTATION.vertical)
        ? "1em"
        : "0.5em",
  };

  // Generating unique draggable ID for the widget to be dragged and dropped
  const draggableId = `${widgetType}/${widgetId}/${uuidv4()}`;

  // Generating unique ID for the widget component in order for PDF export to work
  const widgetComponentId = `${primaryLabel}/${childContainerId}`;

  // Rendering the widget
  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={widgetContainerStyles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderCalendar}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      id={widgetComponentId}
      onContextMenu={handleExportPDFContextMenu}
      sx={widgetContainerStyles}
    >
      {renderCalendar}
      {widgetConfig && (
        <ExportPDF
          exportComponentId={widgetComponentId}
          exportPDFContextMenu={exportPDFContextMenu}
          handleExportPDFContextMenuClose={handleExportPDFContextMenuClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Calendar;
