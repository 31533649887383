function convertArrayOfObjectsToCSV(array) {
  let result = "";

  // Separate each column with a comma
  const columnDelimiter = ",";

  // Separate each row with a new line
  const lineDelimiter = "\n";

  // Get all the fields of CSV
  const keys = Object.keys(array[0]);

  // Adding the fields header
  result += keys.join(columnDelimiter);

  // Adding the new line after the header
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    // Adding a row for each item
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
}

export default function downloadCSV(array, name) {
  // Convert the array of records to CSV string
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  // Create a hidden link element to download the CSV file
  const link = document.createElement("a");

  // Set the CSV file name
  const filename = `${name}.csv`;

  if (!csv.match(/^data:text\/csv/i)) csv = `data:text/csv;charset=utf-8,${csv}`;

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
