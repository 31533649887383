import { useState } from "react";

// Third party library components
import { produce } from "immer";

// NMS dashboard RTK-Query hooks
import { useSaveLayoutMutation } from "features/apiSlice";

// NMS dashboard configs
import { DUMMY_WIDGET_ID, WIDGET_TYPES } from "../configs/widgetsConfigs";

// NMS dashboard components
import EditButton from "./EditButton";
import SavingSpinner from "./SavingSpinner";
import SaveMessage from "./SaveMessage";
import Structure from "./Structure";

function Layout({ dashboardId, layout, username, isAdmin, popupMode, handleBreadcrumbs }) {
  const [saveLayout] = useSaveLayoutMutation();
  const [modifiableLayout, setModifiableLayout] = useState(layout);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);

  // Handler to toggle save message snackbar
  const toggleSaveMessage = () => setShowSaveMessage((prevShowSaveMessage) => !prevShowSaveMessage);

  // Handler to handle drag and drop of widgets in modifiable layout
  const handleOnDrag = (result) => {
    const { destination, source, draggableId } = result;

    // If the widget is dropped outside the droppable area
    if (!destination) return;

    // If the widget is dropped at the same position
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    // Retrieve Droppable Ids
    const sourceMasterContainerId = source.droppableId.split("/")[0];
    const sourceChildContainerId = source.droppableId.split("/")[1];
    const destinationMasterContainerId = destination.droppableId.split("/")[0];
    const destinationChildContainerId = destination.droppableId.split("/")[1];

    // Retrieve dragged widgetType and widgetId
    const draggedWidgetType = draggableId.split("/")[0];
    const draggedWidgetId = draggableId.split("/")[1];

    // Retrieve source and destination child containers from modifiable layout
    const sourceMasterContainer = modifiableLayout[sourceMasterContainerId];
    const sourceChildContainer = sourceMasterContainer.filter(
      (childContainer) => childContainer.celId === sourceChildContainerId
    )[0];
    const destinationMasterContainer = modifiableLayout[destinationMasterContainerId];
    const destinationChildContainer = destinationMasterContainer.filter(
      (childContainer) => childContainer.celId === destinationChildContainerId
    )[0];

    if (source.droppableId === destination.droppableId) {
      // If the widget is dropped in the same child container of the same master container
      setModifiableLayout((prevModifiableLayout) => {
        const newSourceChildContainerWidgetIds = produce(
          sourceChildContainer.widgetIds,
          (draft) => {
            draft.splice(source.index, 1);
            draft.splice(destination.index, 0, {
              id: draggedWidgetId,
              widgetType: draggedWidgetType,
            });
          }
        );
        const newLayout = produce(prevModifiableLayout, (draft) => {
          draft[sourceMasterContainerId].forEach((childContainer) => {
            if (childContainer.celId === sourceChildContainerId) {
              childContainer.widgetIds = newSourceChildContainerWidgetIds;
            }
          });
        });
        return newLayout;
      });
    } else if (
      sourceMasterContainerId === destinationMasterContainerId &&
      sourceChildContainerId !== destinationChildContainerId
    ) {
      // If the widget is dropped in a different child container of the same master container
      setModifiableLayout((prevModifiableLayout) => {
        const newSourceChildContainerWidgetIds = produce(
          sourceChildContainer.widgetIds,
          (draft) => {
            draft.splice(source.index, 1);
          }
        );
        const newDestinationChildContainerWidgetIds = produce(
          destinationChildContainer.widgetIds,
          (draft) => {
            draft.splice(destination.index, 0, {
              id: draggedWidgetId,
              widgetType: draggedWidgetType,
            });
          }
        );
        const newLayout = produce(prevModifiableLayout, (draft) => {
          draft[sourceMasterContainerId].forEach((childContainer) => {
            if (childContainer.celId === sourceChildContainerId) {
              childContainer.widgetIds = newSourceChildContainerWidgetIds;
            }
            if (childContainer.celId === destinationChildContainerId) {
              childContainer.widgetIds = newDestinationChildContainerWidgetIds;
            }
          });
        });
        return newLayout;
      });
    } else {
      // If the widget is dropped in a different child container of a different master container
      setModifiableLayout((prevModifiableLayout) => {
        const newSourceChildContainerWidgetIds = produce(
          sourceChildContainer.widgetIds,
          (draft) => {
            draft.splice(source.index, 1);
          }
        );
        const newDestinationChildContainerWidgetIds = produce(
          destinationChildContainer.widgetIds,
          (draft) => {
            draft.splice(destination.index, 0, {
              id: draggedWidgetId,
              widgetType: draggedWidgetType,
            });
          }
        );
        const newLayout = produce(prevModifiableLayout, (draft) => {
          draft[sourceMasterContainerId].forEach((childContainer) => {
            if (childContainer.celId === sourceChildContainerId) {
              childContainer.widgetIds = newSourceChildContainerWidgetIds;
            }
          });
          draft[destinationMasterContainerId].forEach((childContainer) => {
            if (childContainer.celId === destinationChildContainerId) {
              childContainer.widgetIds = newDestinationChildContainerWidgetIds;
            }
          });
        });
        return newLayout;
      });
    }
  };

  // Handler to add widgets to the modifiable layout
  const handleAddWidgets = (
    masterContainerId,
    childContainerId,
    chosenDummyWidgets,
    chosenActualWidgets,
    handleAddWidgetsPopupClose
  ) => {
    if (chosenDummyWidgets.length > 0)
      setModifiableLayout((prevModifiableLayout) => {
        const targetMasterContainer = prevModifiableLayout[masterContainerId];
        const targetChildContainer = targetMasterContainer.filter(
          (childContainer) => childContainer.celId === childContainerId
        )[0];

        const newDummyWidgets = produce(targetChildContainer.widgetIds, (draft) => {
          chosenDummyWidgets.forEach((option) => {
            switch (option.value) {
              case WIDGET_TYPES.calendar:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.calendar,
                });
                break;
              case WIDGET_TYPES.counter:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.counter,
                });
                break;
              case WIDGET_TYPES.piechart:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.piechart,
                });
                break;
              case WIDGET_TYPES.doughnutchart:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.doughnutchart,
                });
                break;
              case WIDGET_TYPES.barchart:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.barchart,
                });
                break;
              case WIDGET_TYPES.linechart:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.linechart,
                });
                break;
              case WIDGET_TYPES.serverTable:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.serverTable,
                });
                break;
              case WIDGET_TYPES.UITable:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.UITable,
                });
                break;
              case WIDGET_TYPES.graphTopology:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.graphTopology,
                });
                break;
              case WIDGET_TYPES.topology:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.topology,
                });
                break;
              case WIDGET_TYPES.smartTopology:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.smartTopology,
                });
                break;
              case WIDGET_TYPES.iFrame:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.iFrame,
                });
                break;
              case WIDGET_TYPES.gauge:
                draft.push({
                  id: DUMMY_WIDGET_ID,
                  widgetType: WIDGET_TYPES.gauge,
                });
                break;
              default:
                break;
            }
          });
        });

        const newLayout = produce(prevModifiableLayout, (draft) => {
          draft[masterContainerId].forEach((childContainer) => {
            if (childContainer.celId === childContainerId)
              childContainer.widgetIds = newDummyWidgets;
          });
        });

        return newLayout;
      });
    if (chosenActualWidgets.length > 0) {
      setModifiableLayout((prevModifiableLayout) => {
        const targetMasterContainer = prevModifiableLayout[masterContainerId];
        const targetChildContainer = targetMasterContainer.filter(
          (childContainer) => childContainer.celId === childContainerId
        )[0];

        const newActualWidgets = produce(targetChildContainer.widgetIds, (draft) => {
          chosenActualWidgets.forEach((option) => {
            draft.push({
              id: option.label,
              widgetType: null,
            });
          });
        });

        const newLayout = produce(prevModifiableLayout, (draft) => {
          draft[masterContainerId].forEach((childContainer) => {
            if (childContainer.celId === childContainerId)
              childContainer.widgetIds = newActualWidgets;
          });
        });

        return newLayout;
      });
    }
    handleAddWidgetsPopupClose();
  };

  // Handler to switch widget type in modifiable layout
  const handleSwitchWidgetType = (masterContainerId, childContainerId, index, newWidgetType) => {
    setModifiableLayout(
      produce((draft) => {
        const targetChildContainer = draft[masterContainerId].filter(
          (childContainer) => childContainer.celId === childContainerId
        )[0];
        targetChildContainer.widgetIds[index].widgetType = newWidgetType;
      })
    );
  };

  // Handler to delete a widget from modifiable layout
  const handleDeleteWidget = (masterContainerId, childContainerId, index) => {
    setModifiableLayout(
      produce((draft) => {
        const targetChildContainer = draft[masterContainerId].filter(
          (childContainer) => childContainer.celId === childContainerId
        )[0];
        targetChildContainer.widgetIds.splice(index, 1);
      })
    );
  };

  // Handler to save the modifiable layout
  const handleDashboardEditAndSave = () => {
    if (isEditMode) {
      setIsSaving(true);
      saveLayout({
        userName: username,
        widgetId: dashboardId,
        dashboard: modifiableLayout,
      })
        .unwrap()
        .then((res) => {
          setIsSaving(false);
          setModifiableLayout(res.data.dashboard);
          toggleSaveMessage();
        })
        .catch((error) => {
          setIsSaving(false);
          console.error(error);
        });
    }
    setIsEditMode((prevEditMode) => !prevEditMode);
  };

  return (
    <>
      {!popupMode && isAdmin && (
        <EditButton
          handleDashboardEditAndSave={handleDashboardEditAndSave}
          isEditMode={isEditMode}
        />
      )}
      <SavingSpinner isSaving={isSaving} />
      <SaveMessage showSaveMessage={showSaveMessage} toggleSaveMessage={toggleSaveMessage} />
      <Structure
        modifiableLayout={modifiableLayout}
        handleOnDrag={handleOnDrag}
        handleAddWidgets={handleAddWidgets}
        handleSwitchWidgetType={handleSwitchWidgetType}
        handleDeleteWidget={handleDeleteWidget}
        handleBreadcrumbs={handleBreadcrumbs}
        isEditMode={isEditMode}
        isAdmin={isAdmin}
      />
    </>
  );
}

export default Layout;
