// MUI components
import { Chip, Autocomplete, TextField } from "@mui/material";

// NMS dashboard configs
import UI_CONFIGS from "../../configs/UIConfigs";

function DummyWidgetAutocomplete({ chosenDummyWidgets, handleChosenDummyWidgets, isDarkMode }) {
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      multiple
      autoComplete
      filterSelectedOptions
      onChange={handleChosenDummyWidgets}
      options={UI_CONFIGS}
      renderTags={(value, getTagProps) =>
        chosenDummyWidgets.map((option, index) => (
          <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          color={isDarkMode ? "white" : "black"}
          sx={(theme) => ({
            marginTop: ".5em",
            backgroundColor: isDarkMode ? theme.palette.dark.main : theme.palette.white.main,
            "& input::placeholder": {
              color: isDarkMode ? theme.palette.text.main : "",
            },
          })}
          {...params}
          variant="filled"
          placeholder="Please select widget type"
        />
      )}
    />
  );
}

export default DummyWidgetAutocomplete;
