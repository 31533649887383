import { forwardRef, useState } from "react";

// MUI components
import { Dialog, DialogContent, DialogActions, Slide } from "@mui/material";

// Template custom hooks
import { useMaterialUIController } from "context";

// Template components
import MDButton from "components/MDButton";

// NMS dashboard RTK-Query hooks
import { useGetDataQuery } from "features/apiSlice";

// NMS dashboard components
import PopupWidgetControl from "./PopupWidgetControl";
import DummyWidgetAutocomplete from "./DummyWidgetAutocomplete";
import RealWidgetAutocomplete from "./RealWidgetAutocomplete";

// Transition for the popup when it opens
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function AddWidgetPopup({
  masterContainerId,
  childContainerId,
  isAddWidgetsPopupOpen,
  handleAddWidgetsPopupClose,
  handleAddWidgets,
}) {
  const [isDummy, setIsDummy] = useState(true);
  const [isRealWidgetAutocompleteOpen, setIsRealWidgetAutocompleteOpen] = useState(false);
  const [chosenDummyWidgets, setChosenDummyWidgets] = useState([]);
  const [chosenRealWidgets, setChosenRealWidgets] = useState([]);
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  // Fetching the widget list when the widget ID autocomplete is opened and the user is not searching for a dummy widget
  const { data } = useGetDataQuery("/dashboard/widgets", {
    skip: isDummy || !isRealWidgetAutocompleteOpen,
    refetchOnReconnect: true,
  });

  // Handler to switch between dummy and real widgets
  const toggleDummyRadio = () => setIsDummy((prevIsDummy) => !prevIsDummy);

  // Handler to choose dummy widgets
  const handleChosenDummyWidgets = (event, selectedOptions, reason, details) =>
    setChosenDummyWidgets(selectedOptions);

  // Handler to choose real widgets
  const handleChosenRealWidgets = (event, selectedOptions, reason, details) =>
    setChosenRealWidgets(selectedOptions);

  // Handler to open the real widget autocomplete
  const handleOpenRealWidgetAutocomplete = () => setIsRealWidgetAutocompleteOpen(true);

  return (
    <Dialog
      open={isAddWidgetsPopupOpen}
      TransitionComponent={Transition}
      onClose={handleAddWidgetsPopupClose}
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode ? theme.palette.background.card : theme.palette.white.main,
        })}
      >
        <PopupWidgetControl
          isDummy={isDummy}
          toggleDummyRadio={toggleDummyRadio}
          handleChosenDummyWidgets={handleChosenDummyWidgets}
          handleChosenRealWidgets={handleChosenRealWidgets}
        />
        {isDummy ? (
          <DummyWidgetAutocomplete
            chosenDummyWidgets={chosenDummyWidgets}
            handleChosenDummyWidgets={handleChosenDummyWidgets}
            isDarkMode={isDarkMode}
          />
        ) : (
          <RealWidgetAutocomplete
            isRealWidgetAutocompleteOpen={isRealWidgetAutocompleteOpen}
            handleOpenRealWidgetAutocomplete={handleOpenRealWidgetAutocomplete}
            chosenRealWidgets={chosenRealWidgets}
            handleChosenRealWidgets={handleChosenRealWidgets}
            isDarkMode={isDarkMode}
            data={data}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode ? theme.palette.background.card : theme.palette.white.main,
        })}
      >
        <MDButton
          disabled={!isDummy && isRealWidgetAutocompleteOpen && !data}
          variant="gradient"
          color="info"
          onClick={() =>
            handleAddWidgets(
              masterContainerId,
              childContainerId,
              chosenDummyWidgets,
              chosenRealWidgets,
              handleAddWidgetsPopupClose
            )
          }
        >
          Add Widgets
        </MDButton>
        <MDButton variant="gradient" color="primary" onClick={handleAddWidgetsPopupClose}>
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddWidgetPopup;
