import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import NMSDashboard from "../NMSDashboard";

function PopUp({ open, isAdmin, handleClose, dashboardId }) {
  const { darkMode } = useMaterialUIController()[0];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: darkMode ? theme.palette.background.default : theme.palette.white.main,
        })}
      >
        <NMSDashboard id={dashboardId} isAdmin={isAdmin} dashboardType="dashboard" popupMode />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: darkMode ? theme.palette.background.default : theme.palette.white.main,
          marginTop: "-1em",
        })}
      >
        <MDButton color="primary" type="button" onClick={handleClose}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default PopUp;
