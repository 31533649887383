import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Client } from "@stomp/stompjs";
import updateTreeNode from "layouts/dyotis/NMS/utils/updateTreeNode";
import axios from "axios";
import variables from "globals/variables";

export const CoralTelecom = createApi({
  reducerPath: "CoralTelecom",
  baseQuery: fetchBaseQuery({
    baseUrl: variables.api.telemetry,
    // timeout: 10000,
  }),
  tagTypes: ["saveLayout"],
  endpoints: (builder) => ({
    getLayout: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      providesTags: ["saveLayout"],
    }),
    saveLayout: builder.mutation({
      query: (payload) => ({
        url: "/dashboard/saveDashboard",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["saveLayout"],
    }),
    getWidgetConfig: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
    }),
    getWidgetData: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
    }),
    getData: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
    }),
    getAssetSensorsData: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response.status,
    }),
    getLocations: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      providesTags: ["locations"],
    }),
    saveLocations: builder.mutation({
      query: (payload) => ({
        url: "/location/update",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["locations"],
    }),
    getPopulatedWidgetListData: builder.query({
      queryFn: (widgetIds) => {
        const promises = widgetIds.map(async (id) => {
          if (id === 999999999) {
            const dummySmartAssetWidget = {
              primaryLabel: "Smart Asset",
              secondaryLabel: null,
              getDataURL: null,
              setDataURL: null,
              apiCode: null,
              hasWebSocketUpdate: null,
              refreshInterval: null,
              thresholdLower: null,
              thresholdUpper: null,
              location: null,
              legendField: null,
              valueField: null,
              columns: null,
              actions: null,
              legendFieldDataType: null,
              valueFieldDataType: null,
              widgetType: "smartAsset",
              showLegends: null,
              assetId: window.assetId,
              assetName: window.assetName,
              imageName: window.imageName,
              width: window.width,
              height: window.height,
            };

            return { id, data: dummySmartAssetWidget };
          }
          if (id === 999999997) {
            const dummySmartAssetWidget = {
              primaryLabel: "Smart Asset",
              secondaryLabel: null,
              getDataURL: null,
              setDataURL: null,
              apiCode: null,
              hasWebSocketUpdate: null,
              refreshInterval: null,
              thresholdLower: null,
              thresholdUpper: null,
              location: null,
              legendField: null,
              valueField: null,
              columns: null,
              actions: null,
              legendFieldDataType: null,
              valueFieldDataType: null,
              widgetType: "smartAsset",
              showLegends: null,
              imageName: null,
              width: 1110,
              height: 718.5,
            };

            return { id, data: dummySmartAssetWidget };
          }
          if (id === 999999998) {
            const dummySensorPopupWidget = {
              primaryLabel: window.sensorType,
              secondaryLabel: null,
              getDataURL: window.dynamicURL,
              setDataURL: null,
              apiCode: null,
              hasWebSocketUpdate: null,
              refreshInterval: window.updateInterval,
              thresholdLower: null,
              thresholdUpper: null,
              location: null,
              legendField: "eventEpoch",
              valueField: "attributeValue",
              columns: null,
              actions: null,
              legendFieldDataType: "string",
              valueFieldDataType: "epoch",
              widgetType: "line-chart",
              showLegends: null,
              assetId: null,
              assetName: null,
              imageName: null,
              width: null,
              height: null,
            };

            return { id, data: dummySensorPopupWidget };
          }
          if (id === "Dummy-ID") return { id: "Dummy-ID", data: null };
          const response = await axios(
            `https://telemetry.coraltele.com/telemetry/api/v2/dashboard/widget/${id}`
          );
          return {
            id,
            data: response.data.data,
          };
        });
        return Promise.all(promises).then((results) => ({ data: results }));
      },
    }),
    getTopologyWidgetData: builder.query({
      // query: (getDataURL) => "https://server-mh6e.onrender.com/getTopologyData", // for testing purposes
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const ws = new Client({
          brokerURL: "wss://telemetry.coraltele.com/app/v2/messaging/messages",
          reconnectDelay: 0,
        });
        try {
          await cacheDataLoaded;
          ws.onConnect = function () {
            ws.subscribe("/app/v2/messaging/topic/events", (message) => {
              const outage = JSON.parse(message.body);
              updateCachedData((draft) => updateTreeNode(draft, outage));
            });
          };

          ws.activate();
        } catch (error) {
          console.log(error);
        }
        await cacheEntryRemoved;
      },
    }),
  }),
});

export const {
  useGetLayoutQuery,
  useSaveLayoutMutation,
  useGetWidgetConfigQuery,
  useGetWidgetDataQuery,
  useGetDataQuery,
  useGetAssetSensorsDataQuery,
  useGetLocationsQuery,
  useSaveLocationsMutation,
  useGetPopulatedWidgetListDataQuery,
  useGetTopologyWidgetDataQuery,
} = CoralTelecom;
