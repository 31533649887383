// MUI components
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

function PopupWidgetControl({
  isDummy,
  toggleDummyRadio,
  handleChosenDummyWidgets,
  handleChosenRealWidgets,
}) {
  return (
    <FormControl>
      <FormLabel
        sx={(theme) => ({
          color: theme.palette.text.main,
        })}
      >
        Add widgets
      </FormLabel>
      <RadioGroup row>
        <FormControlLabel
          checked={isDummy}
          value="widgetType"
          control={<Radio />}
          onChange={() => {
            handleChosenRealWidgets({}, []);
            handleChosenDummyWidgets({}, []);
            toggleDummyRadio();
          }}
          label="Add widget type"
        />
        <FormControlLabel
          checked={!isDummy}
          value="widgetId"
          control={<Radio />}
          onChange={() => {
            handleChosenRealWidgets({}, []);
            handleChosenDummyWidgets({}, []);
            toggleDummyRadio();
          }}
          label="Add widget ID"
        />
      </RadioGroup>
    </FormControl>
  );
}

export default PopupWidgetControl;
