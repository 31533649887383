import { useMemo } from "react";

// Third party library components
import { DragDropContext } from "@hello-pangea/dnd";

// MUI components
import { Grid } from "@mui/material";

// NMS dashboard utilities
import RenderContainer from "../../utils/RenderContainer";
import getInnerBodyBreakpoints from "../../utils/getInnerBodyBreakpoints";

// NMS dashboard configs
import { CONTAINER_TYPES } from "../../configs/containersConfigs";

function Structure({
  modifiableLayout,
  handleOnDrag,
  handleAddWidgets,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  isEditMode,
  isAdmin,
}) {
  const { xl, lg, md, sm, xs } = useMemo(() => getInnerBodyBreakpoints(modifiableLayout), []);

  return (
    <DragDropContext onDragEnd={handleOnDrag}>
      {modifiableLayout.header && modifiableLayout.header.length > 0 && (
        <Grid>
          {RenderContainer(
            CONTAINER_TYPES.header,
            modifiableLayout,
            handleAddWidgets,
            handleSwitchWidgetType,
            handleDeleteWidget,
            handleBreadcrumbs,
            isEditMode,
            isAdmin
          )}
        </Grid>
      )}
      <Grid container>
        {modifiableLayout.leftSidebar &&
          modifiableLayout.leftSidebar.length > 0 &&
          RenderContainer(
            CONTAINER_TYPES.leftSidebar,
            modifiableLayout,
            handleAddWidgets,
            handleSwitchWidgetType,
            handleDeleteWidget,
            handleBreadcrumbs,
            isEditMode,
            isAdmin
          )}
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          {modifiableLayout.innerHeader &&
            modifiableLayout.innerHeader.length > 0 &&
            RenderContainer(
              CONTAINER_TYPES.innerHeader,
              modifiableLayout,
              handleAddWidgets,
              handleSwitchWidgetType,
              handleDeleteWidget,
              handleBreadcrumbs,
              isEditMode,
              isAdmin
            )}
          <Grid item container>
            {modifiableLayout.innerLeftSidebar &&
              modifiableLayout.innerLeftSidebar.length > 0 &&
              RenderContainer(
                CONTAINER_TYPES.innerLeftSidebar,
                modifiableLayout,
                handleAddWidgets,
                handleSwitchWidgetType,
                handleDeleteWidget,
                handleBreadcrumbs,
                isEditMode,
                isAdmin
              )}
            {modifiableLayout.center &&
              modifiableLayout.center.length > 0 &&
              RenderContainer(
                CONTAINER_TYPES.center,
                modifiableLayout,
                handleAddWidgets,
                handleSwitchWidgetType,
                handleDeleteWidget,
                handleBreadcrumbs,
                isEditMode,
                isAdmin
              )}
            {modifiableLayout.innerRightSidebar &&
              modifiableLayout.innerRightSidebar.length > 0 &&
              RenderContainer(
                CONTAINER_TYPES.innerRightSidebar,
                modifiableLayout,
                handleAddWidgets,
                handleSwitchWidgetType,
                handleDeleteWidget,
                handleBreadcrumbs,
                isEditMode,
                isAdmin
              )}
          </Grid>
          {modifiableLayout.innerFooter &&
            modifiableLayout.innerFooter.length > 0 &&
            RenderContainer(
              CONTAINER_TYPES.innerFooter,
              modifiableLayout,
              handleAddWidgets,
              handleSwitchWidgetType,
              handleDeleteWidget,
              handleBreadcrumbs,
              isEditMode,
              isAdmin
            )}
        </Grid>
        {modifiableLayout.rightSidebar &&
          modifiableLayout.rightSidebar.length > 0 &&
          RenderContainer(
            CONTAINER_TYPES.rightSidebar,
            modifiableLayout,
            handleAddWidgets,
            handleSwitchWidgetType,
            handleDeleteWidget,
            handleBreadcrumbs,
            isEditMode,
            isAdmin
          )}
      </Grid>
      {modifiableLayout.footer && modifiableLayout.footer.length > 0 && (
        <Grid>
          {RenderContainer(
            CONTAINER_TYPES.footer,
            modifiableLayout,
            handleAddWidgets,
            handleSwitchWidgetType,
            handleDeleteWidget,
            handleBreadcrumbs,
            isEditMode,
            isAdmin
          )}
        </Grid>
      )}
    </DragDropContext>
  );
}

export default Structure;
