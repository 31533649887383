import { useState, useMemo } from "react";

// Third party utilities
import { v4 as uuidv4 } from "uuid";

// Third party library components
import { Draggable } from "@hello-pangea/dnd";
import { Line } from "react-chartjs-2";

// MUI components
import { Card, CircularProgress, Icon, useMediaQuery } from "@mui/material";

// Template assets
import colors from "assets/theme/base/colors";

// Template components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NMS dashboard RTK-Query hooks
import { useGetWidgetDataQuery } from "features/apiSlice";

// NMS dashboard configs
import { LINECHART_DROPDOWN_LIST } from "../../../../configs/dropdownConfigs";
import {
  DUMMY_WIDGET_LABELS,
  WIDGET_LOADING_LABEL,
  WIDGET_EMPTY_LABEL,
  WIDGET_ERROR_LABEL,
} from "../../../../configs/widgetsConfigs";
import LINECHART_AXIS_STEP_SIZE from "../../../../configs/chartConfigs";
import { CONTAINER_ORIENTATION } from "../../../../configs/containersConfigs";
import CHART_DUMMY_DATA from "../../../../data/chartData";
import configs from "./configs";

// NMS dashboard logs
import requestErrorLog from "../../../../logs/error";

// NMS dashboard components
import PopUp from "../../../../PopUp";
import Dropdown from "../../../Dropdown";
import ExportPDF from "../../../ExportPDF";

function Linechart({
  index,
  widgetConfig,
  widgetId,
  widgetType,
  widgetHeight,
  masterContainerId,
  childContainerId,
  isEditMode,
  isAdmin,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  containerOrientation,
}) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpDashboardId, setPopUpDashboardId] = useState(null);
  const [exportPDFContextMenu, setExportPDFContextMenu] = useState(null);

  // Handler for context menu for the widget
  const handleExportPDFContextMenu = (event) => {
    event.preventDefault();
    setExportPDFContextMenu(
      exportPDFContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  // Handler for closing context menu for the widget
  const handleExportPDFContextMenuClose = () => setExportPDFContextMenu(null);

  // Widget configuration for dummy widget
  let getDataURL = null;
  let refreshInterval = 0;
  let actions = [];
  let thresholdLower = null;
  let thresholdUpper = null;

  // Widget configuration for actual widget
  if (widgetConfig) {
    getDataURL = widgetConfig.getDataURL;
    refreshInterval = widgetConfig.refreshInterval ? widgetConfig.refreshInterval : 0;
    actions = widgetConfig.actions ? widgetConfig.actions : [];
    thresholdLower = widgetConfig.thresholdLower;
    thresholdUpper = widgetConfig.thresholdUpper;
  }

  // Fetching data for the widget
  const { data, isError, error } = useGetWidgetDataQuery(getDataURL, {
    pollingInterval: refreshInterval,
    refetchOnReconnect: true,
    skip: !getDataURL,
  });

  // If error, return error message
  useMemo(() => requestErrorLog(isError, error, widgetId), [error]);

  // Extracting labels and values for datapoints of chart from fetched data
  const dataPointLabels = [];
  const dataPointValues = [];

  if (data)
    data.forEach((dataPoint, dataPointIndex) => {
      dataPointValues.push(dataPoint[widgetConfig.valueField]);
      if (
        widgetConfig.legendField === "eventEpoch" ||
        widgetConfig.valueFieldDataType === "epoch"
      ) {
        if (dataPointIndex % LINECHART_AXIS_STEP_SIZE === 0) {
          const event = new Date(dataPoint[widgetConfig.legendField] * 1000);
          const epochTime = event.toTimeString().substring(0, 8);
          dataPointLabels.push(epochTime);
        } else dataPointLabels.push("");
      } else {
        dataPointLabels.push(dataPoint[widgetConfig.legendField]);
      }
    });

  // Configurations for the chart
  let datasets = [
    {
      label: data ? "" : "Dummy-data-label",
      color: "info",
      data: data ? dataPointValues : CHART_DUMMY_DATA.values,
    },
  ];
  if (thresholdLower && thresholdUpper)
    datasets = [
      ...datasets,
      {
        label: data ? "Low" : "Dummy-lower-threshold",
        color: "primary",
        data: Array(data ? dataPointValues.length : CHART_DUMMY_DATA.values.length).fill(
          thresholdLower,
          0
        ),
      },
      {
        label: data ? "High" : "Dummy-upper-threshold",
        color: "success",
        data: Array(data ? dataPointValues.length : CHART_DUMMY_DATA.values.length).fill(
          thresholdUpper,
          0
        ),
      },
    ];
  else if (thresholdLower)
    datasets = [
      ...datasets,
      {
        label: data ? "Low" : "Dummy-lower-threshold",
        color: "primary",
        data: Array(data ? dataPointValues.length : CHART_DUMMY_DATA.values.length).fill(
          thresholdLower,
          0
        ),
      },
    ];
  else if (thresholdUpper)
    datasets = [
      ...datasets,
      {
        label: data ? "High" : "Dummy-upper-threshold",
        color: "success",
        data: Array(data ? dataPointValues.length : CHART_DUMMY_DATA.values.length).fill(
          thresholdUpper,
          0
        ),
      },
    ];

  const chartData = {
    labels: data ? dataPointLabels : CHART_DUMMY_DATA.labels,
    data: datasets
      ? datasets.map((dataset) => ({
          ...dataset,
          tension: 0,
          pointRadius: 1,
          borderWidth: 2,
          backgroundColor: "transparent",
          fill: true,
          pointBackgroundColor: colors[dataset.color]
            ? colors[dataset.color || "dark"].main
            : colors.dark.main,
          borderColor: colors[dataset.color]
            ? colors[dataset.color || "dark"].main
            : colors.dark.main,
          maxBarThickness: 6,
        }))
      : [],
  };

  const { linechartData, linechartOptions } = configs(
    widgetConfig.legendField,
    widgetConfig.valueFieldDataType,
    chartData.labels,
    chartData.data,
    actions,
    setPopUpOpen,
    setPopUpDashboardId,
    handleBreadcrumbs
  );

  // Rendering logic for the widget
  let primaryLabel = null;
  let secondaryLabel = null;
  let chart = null;
  let errorIcon = null;
  let dropdownOptions = isAdmin ? LINECHART_DROPDOWN_LIST : LINECHART_DROPDOWN_LIST.slice(0, -1);

  // If actual widget is being rendered
  if (widgetConfig) {
    if (error) {
      // If request timeout occurs or server responds with an error
      primaryLabel = WIDGET_ERROR_LABEL;
      secondaryLabel = WIDGET_EMPTY_LABEL;
      errorIcon = (
        <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
      dropdownOptions = isAdmin
        ? LINECHART_DROPDOWN_LIST.slice(LINECHART_DROPDOWN_LIST.length - 1)
        : [];
    } else if (data) {
      // If data is available for the actual widget
      primaryLabel = widgetConfig.primaryLabel ? widgetConfig.primaryLabel : WIDGET_EMPTY_LABEL;
      secondaryLabel = widgetConfig.secondaryLabel
        ? widgetConfig.secondaryLabel
        : WIDGET_EMPTY_LABEL;
      chart = (
        <MDBox height={widgetHeight}>
          <Line data={linechartData} options={linechartOptions} />
          <PopUp
            isAdmin={isAdmin}
            open={popUpOpen}
            handleClose={() => setPopUpOpen(false)}
            dashboardId={popUpDashboardId}
          />
        </MDBox>
      );
    } else {
      // If data is currently being fetched for the actual widget
      primaryLabel = WIDGET_LOADING_LABEL;
      secondaryLabel = WIDGET_EMPTY_LABEL;
      errorIcon = (
        <MDBox sx={{ marginLeft: "auto", color: "red" }}>
          <CircularProgress color="white" size={20} />
        </MDBox>
      );
      dropdownOptions = isAdmin
        ? LINECHART_DROPDOWN_LIST.slice(LINECHART_DROPDOWN_LIST.length - 1)
        : [];
    }
  } else {
    // If dummy widget is being rendered
    primaryLabel = DUMMY_WIDGET_LABELS.primaryLabel;
    secondaryLabel = DUMMY_WIDGET_LABELS.secondaryLabel;
    chart = (
      <MDBox height={widgetHeight}>
        <Line data={linechartData} options={linechartOptions} />
      </MDBox>
    );
    errorIcon = (
      <Icon sx={{ marginLeft: "auto", color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  // Rendering the chart
  const renderChart = (
    <MDBox p={2} position="relative">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">insights</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {errorIcon}
        {isEditMode && dropdownOptions.length > 0 && (
          <Dropdown
            index={index}
            options={dropdownOptions}
            masterContainerId={masterContainerId}
            childContainerId={childContainerId}
            handleSwitchWidgetType={handleSwitchWidgetType}
            handleDeleteWidget={handleDeleteWidget}
          />
        )}
      </MDBox>
      {useMemo(() => chart, [data, error, popUpOpen])}
    </MDBox>
  );

  // Check if the screen size is medium or below
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Stylings for the widget container
  const widgetContainerStyles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop:
      index > 0 && (matches || containerOrientation === CONTAINER_ORIENTATION.vertical)
        ? "1em"
        : "0.5em",
  };

  // Generating unique draggable ID for the widget to be dragged and dropped
  const draggableId = `${widgetType}/${widgetId}/${uuidv4()}`;

  // Generating unique ID for the widget component in order for PDF export to work
  const widgetComponentId = `${primaryLabel}/${childContainerId}`;

  // Returning the widget
  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={widgetContainerStyles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderChart}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      id={widgetComponentId}
      onContextMenu={handleExportPDFContextMenu}
      sx={widgetContainerStyles}
    >
      {renderChart}
      {widgetConfig && (
        <ExportPDF
          exportComponentId={widgetComponentId}
          exportPDFContextMenu={exportPDFContextMenu}
          handleExportPDFContextMenuClose={handleExportPDFContextMenuClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Linechart;
