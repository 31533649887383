import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Backdrop, CircularProgress } from "@mui/material";
import { useGetLocationsQuery, useSaveLocationsMutation } from "features/apiSlice";
import MapImage from "../MapImage";
import AssignLocationPopup from "../AssignLocationPopup";
import Location from "../Location";
import Label from "../Label";
import SMART_TOPOLOGY_MARKER_SIZE from "../../../../configs/smartTopologyConfigs";
import smartTopologyMap from "../../../../assets/SmartTopologyMap.jpg";

const DEFAULT_BUFFER_LEFT = 8;
const DEFAULT_BUFFER_TOP = 7;

function SmartTopologyRoot({
  isAdmin,
  isEditMode,
  smartTopologyData,
  aggregatedTree,
  parentRefCurrent,
  handleParentRefCurrent,
  widgetConfig,
  getDataURL,
  bufferLeft = DEFAULT_BUFFER_LEFT,
  bufferTop = DEFAULT_BUFFER_TOP,
}) {
  const [droppedLocations, setDroppedLocations] = useState(() =>
    widgetConfig ? [] : JSON.parse(localStorage.getItem("SmartTopologyDroppedLocations")) || []
  );
  const [undroppedLocations, setUndroppedLocations] = useState(() =>
    widgetConfig ? [] : JSON.parse(localStorage.getItem("SmartTopologyUndroppedLocations")) || []
  );
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [assignLocationPopupOpen, setAssignLocationPopupOpen] = useState(false);
  const [currentLocationCoordinates, setCurrentLocationCoordinates] = useState(null);
  const [imageAspectRatio, setImageAspectRatio] = useState(null);
  const [saveLocations] = useSaveLocationsMutation();
  const markerSize = SMART_TOPOLOGY_MARKER_SIZE;

  const { data, isError, error } = useGetLocationsQuery("/dashboard/locations", {
    refetchOnReconnect: true,
    skip: !getDataURL || !imageAspectRatio || !parentRefCurrent,
  });

  // useMemo(() => requestErrorLog(isError, error, widgetId), [error]);

  useEffect(() => {
    // Load the image
    const image = new Image();
    image.src = smartTopologyMap;

    // Once the image is loaded, calculate the aspect ratio
    image.onload = () => {
      setLoadingLocations(true);
      const { width, height } = image;
      const aspectRatioValue = width / height;
      setImageAspectRatio(aspectRatioValue);
    };
  }, []);

  useEffect(() => {
    if (widgetConfig) {
      if (error) setLoadingLocations(false);
      else if (data && parentRefCurrent) {
        setDroppedLocations(
          data
            .filter(
              (location) =>
                location.xcoordinate !== "" &&
                location.ycoordinate !== "" &&
                location.configuration !== "" &&
                location.configuration !== null &&
                location.configuration !== "null"
            )
            .map((location) => {
              const configuration = JSON.parse(location.configuration);

              return {
                ...location,
                xcoordinate:
                  (parseFloat(location.xcoordinate) * parentRefCurrent.offsetWidth) / 100,
                ycoordinate:
                  (parseFloat(location.ycoordinate) * parentRefCurrent.offsetHeight) / 100,
                configuration: {
                  ...configuration,
                  labelX: (configuration.labelX * parentRefCurrent.offsetWidth) / 100,
                  labelY: (configuration.labelY * parentRefCurrent.offsetHeight) / 100,
                },
              };
            })
        );
        setUndroppedLocations(
          data.filter((location) => location.xcoordinate === "" || location.ycoordinate === "")
        );
        setLoadingLocations(false);
      }
    } else setLoadingLocations(false);

    return () => {
      setDroppedLocations([]);
      setUndroppedLocations([]);
    };
  }, [data, error, parentRefCurrent, imageAspectRatio]);

  const handleSaveConfiguration = (newLocation) => {
    const locationXPercentage = (newLocation.xcoordinate * 100) / parentRefCurrent.offsetWidth;
    const locationYPercentage = (newLocation.ycoordinate * 100) / parentRefCurrent.offsetHeight;
    const labelXPercentage =
      (newLocation.configuration.labelX * 100) / parentRefCurrent.offsetWidth;
    const labelYPercentage =
      (newLocation.configuration.labelY * 100) / parentRefCurrent.offsetHeight;

    if (
      locationXPercentage < 0 ||
      locationXPercentage > 100 ||
      locationYPercentage < 0 ||
      locationYPercentage > 100 ||
      labelXPercentage < 0 ||
      labelXPercentage > 100 ||
      labelYPercentage < 0 ||
      labelYPercentage > 100
    ) {
      alert("Invalid location coordinates");
      return;
    }

    setLoadingLocations(true);
    saveLocations({
      id: newLocation.id,
      xcoordinate: newLocation.xcoordinate !== "" ? locationXPercentage : "",
      ycoordinate: newLocation.ycoordinate !== "" ? locationYPercentage : "",
      configuration:
        newLocation.configuration !== "" &&
        newLocation.configuration !== null &&
        newLocation.configuration !== "null"
          ? JSON.stringify({
              ...newLocation.configuration,
              labelX: labelXPercentage,
              labelY: labelYPercentage,
            })
          : "",
    })
      .unwrap()
      .then(() => {
        setLoadingLocations(false);
      })
      .catch((err) => {
        setLoadingLocations(false);
        console.log(err);
      });
  };

  const handleAssignLocationPopupOpen = (top, left) => {
    setCurrentLocationCoordinates({
      locationX: left,
      locationY: top,
    });
    setAssignLocationPopupOpen(true);
  };

  const handleAssignLocationPopupClose = (droppedLocation) => {
    if (!droppedLocation) {
      setCurrentLocationCoordinates(null);
      setAssignLocationPopupOpen(false);
      return;
    }

    const newLocation = {
      ...droppedLocation,
      xcoordinate: parseFloat(currentLocationCoordinates.locationX.toFixed(2)) + markerSize / 2,
      ycoordinate: parseFloat(currentLocationCoordinates.locationY.toFixed(2)) + markerSize / 2,
      configuration: {
        labelX: parseFloat(currentLocationCoordinates.locationX.toFixed(2)),
        labelY: parseFloat(currentLocationCoordinates.locationY.toFixed(2)) + 25,
        labelText: droppedLocation.name || droppedLocation.code || "Add label",
        labelOrientation: "horizontal",
        labelFontSize: 7,
      },
    };
    setUndroppedLocations((prevUndroppedLocations) =>
      prevUndroppedLocations.filter((location) => location.uuid !== droppedLocation.uuid)
    );
    setDroppedLocations((prevDroppedLocations) => [...prevDroppedLocations, newLocation]);
    setCurrentLocationCoordinates(null);
    setAssignLocationPopupOpen(false);
    handleSaveConfiguration(newLocation);
  };

  const handleLabelDragStop = (lastX, lastY, labelAssociatedLocation) => {
    const newLocation = {
      ...labelAssociatedLocation,
      configuration: {
        ...labelAssociatedLocation.configuration,
        labelX: lastX,
        labelY: lastY,
      },
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.map((location) =>
        location.uuid === labelAssociatedLocation.uuid ? newLocation : location
      )
    );
    handleSaveConfiguration(newLocation);
  };

  const handleLocationDragStop = (lastX, lastY, draggedLocation) => {
    const newLocation = {
      ...draggedLocation,
      xcoordinate: lastX + markerSize / 2,
      ycoordinate: lastY + markerSize / 2,
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.map((location) =>
        location.uuid === draggedLocation.uuid ? newLocation : location
      )
    );
    handleSaveConfiguration(newLocation);
  };

  return (
    <MDBox
      position="relative"
      height={
        parentRefCurrent && imageAspectRatio ? parentRefCurrent.offsetWidth / imageAspectRatio : 0
      }
    >
      <Backdrop open={loadingLocations} sx={{ position: "absolute", color: "#ccc", zIndex: "98" }}>
        <CircularProgress color="white" />
      </Backdrop>
      <MapImage
        isAdmin={isAdmin}
        isEditMode={isEditMode}
        handleAssignLocationPopupOpen={handleAssignLocationPopupOpen}
        handleParentRefCurrent={handleParentRefCurrent}
        bufferLeft={bufferLeft}
        bufferTop={bufferTop}
      />
      <AssignLocationPopup
        undroppedLocations={undroppedLocations}
        assignLocationPopupOpen={assignLocationPopupOpen}
        handleAssignLocationPopupClose={handleAssignLocationPopupClose}
      />
      {droppedLocations.map((location) => (
        <Location
          key={location.uuid}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          location={location}
          smartTopologyData={smartTopologyData}
          aggregatedTree={aggregatedTree}
          handleLocationDragStop={handleLocationDragStop}
          setDroppedLocations={setDroppedLocations}
          setUndroppedLocations={setUndroppedLocations}
          handleSaveConfiguration={handleSaveConfiguration}
        />
      ))}
      {droppedLocations.map((location) => (
        <Label
          key={location.uuid}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          location={location}
          handleLabelDragStop={handleLabelDragStop}
        />
      ))}
    </MDBox>
  );
}

export default SmartTopologyRoot;
