// Template assets
import typography from "assets/theme/base/typography";

// NMS dashboard utilities
import chartsActionHandle from "../../../../../utils/chartsActionHandle";

// NMS dashboard configs
import chartColors from "../../chartColors";

function configs(
  barLabels,
  data,
  showLegends,
  actions,
  setPopUpOpen,
  setPopUpDashboardId,
  handleBreadcrumbs
) {
  const { gradients } = chartColors;

  // Define the colors of the bars
  const barColors = [];
  data.barColors.forEach((color) => {
    if (color === "info") barColors.push(gradients.info.main);
    else barColors.push(gradients[color].state);
  });

  return {
    barchartData: {
      labels: barLabels,
      datasets: [
        {
          label: data.label,
          backgroundColor: barColors,
          data: data.barValues,
        },
      ],
    },
    barchartOptions: {
      onClick: (event, element) =>
        chartsActionHandle(
          element,
          actions,
          barLabels,
          setPopUpOpen,
          setPopUpDashboardId,
          handleBreadcrumbs
        ),
      animation: {
        tension: {
          easing: "linear",
          from: 1,
          to: 0,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          display: showLegends,
          labels: {
            boxWidth: 15,
            boxHeight: 15,
            generateLabels(chart) {
              const { labels } = chart.data;
              return labels.map((label, i) => ({
                text: `${chart.data.datasets[0].data[i]}: ${label}`,
                fillStyle: chart.data.datasets[0].backgroundColor[i],
                strokeStyle: chart.data.datasets[0].backgroundColor[i],
                lineWidth: 1,
              }));
            },
          },
        },
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
            color: "#9ca2b7",
            padding: 10,
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
