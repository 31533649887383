// NMS dashboard configs
import { CONTAINER_TYPES } from "../configs/containersConfigs";

// NNMS dashboard components
import Row from "../containers/Row";
import Column from "../containers/Column";

export default function RenderContainer(
  masterContainerId,
  modifiableLayout,
  handleAddWidgets,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  isEditMode,
  isAdmin
) {
  const allChildContainers = [];

  // Current master container
  const masterContainer = modifiableLayout[masterContainerId];

  // Render all child containers of the master container along with their widgets
  masterContainer.forEach((childContainer) => {
    switch (masterContainerId) {
      case CONTAINER_TYPES.header:
      case CONTAINER_TYPES.footer:
      case CONTAINER_TYPES.innerHeader:
      case CONTAINER_TYPES.innerFooter:
        allChildContainers.push(
          <Row
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            childContainer={childContainer}
            mediaQueryBreakpoint={CONTAINER_TYPES.header || CONTAINER_TYPES.footer ? "md" : "lg"}
            handleAddWidgets={handleAddWidgets}
            handleSwitchWidgetType={handleSwitchWidgetType}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;
      case CONTAINER_TYPES.leftSidebar:
      case CONTAINER_TYPES.rightSidebar:
      case CONTAINER_TYPES.innerLeftSidebar:
      case CONTAINER_TYPES.innerRightSidebar:
      case CONTAINER_TYPES.center:
        allChildContainers.push(
          <Column
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            childContainer={childContainer}
            handleAddWidgets={handleAddWidgets}
            handleSwitchWidgetType={handleSwitchWidgetType}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;
      default:
        break;
    }
  });

  return allChildContainers;
}

// // NMS dashboard configs
// import { CONTAINER_TYPES } from "../configs/containersConfigs";

// // NNMS dashboard components
// import Header from "../containers/Header";
// import Footer from "../containers/Footer";
// import Sidebar from "../containers/Sidebar";
// import InnerHeader from "../containers/InnerHeader";
// import InnerFooter from "../containers/InnerFooter";
// import InnerSidebar from "../containers/InnerSidebar";
// import Center from "../containers/Center";

// export default function RenderContainer(
//   masterContainerId,
//   modifiableLayout,
//   handleAddWidgets,
//   handleSwitchWidgetType,
//   handleDeleteWidget,
//   handleBreadcrumbs,
//   isEditMode,
//   isAdmin
// ) {
//   const allChildContainers = [];

//   // Current master container
//   const masterContainer = modifiableLayout[masterContainerId];

//   // Render all child containers of the master container along with their widgets
//   masterContainer.forEach((childContainer) => {
//     switch (masterContainerId) {
//       case CONTAINER_TYPES.header:
//         allChildContainers.push(
//           <Header
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       case CONTAINER_TYPES.footer:
//         allChildContainers.push(
//           <Footer
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       case CONTAINER_TYPES.leftSidebar:
//       case CONTAINER_TYPES.rightSidebar:
//         allChildContainers.push(
//           <Sidebar
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       case CONTAINER_TYPES.innerHeader:
//         allChildContainers.push(
//           <InnerHeader
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       case CONTAINER_TYPES.innerFooter:
//         allChildContainers.push(
//           <InnerFooter
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       case CONTAINER_TYPES.innerLeftSidebar:
//       case CONTAINER_TYPES.innerRightSidebar:
//         allChildContainers.push(
//           <InnerSidebar
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       case CONTAINER_TYPES.center:
//         allChildContainers.push(
//           <Center
//             key={childContainer.celId}
//             masterContainerId={masterContainerId}
//             childContainer={childContainer}
//             handleAddWidgets={handleAddWidgets}
//             handleSwitchWidgetType={handleSwitchWidgetType}
//             handleDeleteWidget={handleDeleteWidget}
//             handleBreadcrumbs={handleBreadcrumbs}
//             isEditMode={isEditMode}
//             isAdmin={isAdmin}
//           />
//         );
//         break;
//       default:
//         break;
//     }
//   });

//   return allChildContainers;
// }
