export default function singleContainerLayout(widgetId) {
  return {
    header: [],
    footer: [],
    leftSidebar: [],
    rightSidebar: [],
    innerHeader: [],
    innerFooter: [],
    innerLeftSidebar: [],
    innerRightSidebar: [],
    center: [
      {
        celId: "center1",
        height: "53vh",
        width: {
          xs: 12,
        },
        defaultWidgetHeight: "53vh",
        defaultWidgetWidth: null,
        widgetIds: [
          {
            id: widgetId,
            widgetType: null,
          },
        ],
      },
    ],
  };
}
