import ASSETS_CATEGORIES from "../configs/assetsConfigs";

function helper(root, rootPath, aggregatedTree) {
  const aggregatedTreeRoot = {
    id: root.id,
    active: 0,
    inactive: 0,
    status: root.status,
    severity: root.severity,
    path: [...rootPath, root.id],
  };

  if (root.category !== ASSETS_CATEGORIES.LOCATION) {
    aggregatedTreeRoot.active += root.status;
    aggregatedTreeRoot.inactive += !root.status;
  }

  root.children.forEach((node) => {
    const subAggregatedTreeRoot = helper(node, aggregatedTreeRoot.path, aggregatedTree);

    aggregatedTreeRoot.active += subAggregatedTreeRoot.active;
    aggregatedTreeRoot.inactive += subAggregatedTreeRoot.inactive;
    aggregatedTreeRoot.severity = Math.max(
      aggregatedTreeRoot.severity,
      subAggregatedTreeRoot.severity
    );
  });

  aggregatedTree.push(aggregatedTreeRoot);

  return aggregatedTreeRoot;
}

function getAggregatedTree(data) {
  const aggregatedTree = [];
  const rootPath = [];

  helper(data[0], rootPath, aggregatedTree);

  return aggregatedTree;
}

export default getAggregatedTree;
// last checkpoint
// change this file name and then work on popuptopology
// after that clean the smart topology code
