// MUI components
import { Backdrop, CircularProgress } from "@mui/material";

function SavingSpinner({ isSaving }) {
  return (
    <Backdrop open={isSaving} sx={{ color: "#ccc", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress disableShrink color="white" />
    </Backdrop>
  );
}

export default SavingSpinner;
