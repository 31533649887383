const TOPOLOGY_DUMMY_DATA = [
  {
    level: 1,
    id: "04874a7a-7756-4bd2-9588-6ee68a08ff72",
    nodeId: 0,
    ipAddress: "",
    name: "Coral",
    category: "Location",
    status: true,
    location: null,
    cellId: null,
    children: [
      {
        level: 2,
        id: "1e1f07d8-ead8-42a9-a7b5-10fde42f7822",
        nodeId: 0,
        ipAddress: "",
        name: "Banglore Office",
        category: "Location",
        status: true,
        location: "OCC",
        cellId: null,
        children: [],
      },
      {
        level: 2,
        id: "31ca65d7-4233-4829-a582-8470d7975342",
        nodeId: 0,
        ipAddress: "",
        name: "Noida Office",
        category: "Location",
        status: true,
        location: "HQ",
        cellId: null,
        children: [
          {
            level: 3,
            id: "3e272c66-4495-456d-8d6c-736679e4a53f",
            nodeId: 0,
            ipAddress: "",
            name: "5G Block",
            category: "Location",
            status: true,
            location: "5G",
            cellId: null,
            children: [
              {
                level: 4,
                id: "62245cd9-fef2-4d2e-b040-62af34c388e7",
                nodeId: 0,
                ipAddress: "192.168.8.13",
                name: "192.168.8.13",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "7f91247d-01af-433b-aa2b-8af44b23571f",
                nodeId: 0,
                ipAddress: "192.168.8.14",
                name: "192.168.8.14",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "6d858939-77b4-4cd1-a3a8-18377f90c7ab",
                nodeId: 0,
                ipAddress: "192.168.8.19",
                name: "192.168.8.19",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1b777cb3-3b58-4955-8783-9028eb2efac6",
                nodeId: 0,
                ipAddress: "192.168.8.29",
                name: "192.168.8.29",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "ac0ff082-3e27-4afd-8301-d2eabc005178",
                nodeId: 0,
                ipAddress: "192.168.8.31",
                name: "192.168.8.31",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "436c7ae2-9757-48bd-bbec-c298a9955608",
                nodeId: 0,
                ipAddress: "192.168.8.42",
                name: "192.168.8.42",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "92fce285-806b-499c-9c27-29c40bd32d44",
                nodeId: 0,
                ipAddress: "192.168.8.48",
                name: "192.168.8.48",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "ddc9507b-11d8-4296-ba09-f762a47b0554",
                nodeId: 0,
                ipAddress: "192.168.8.49",
                name: "192.168.8.49",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "29e1b051-92f0-4ed7-819d-13efe1a8cf40",
                nodeId: 0,
                ipAddress: "192.168.8.54",
                name: "192.168.8.54",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "375f8f94-65bd-40ba-a65b-3da9e58633df",
                nodeId: 0,
                ipAddress: "192.168.8.81",
                name: "192.168.8.81",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "14e6cdda-e83a-4eba-8396-f2edb80754f9",
                nodeId: 0,
                ipAddress: "192.168.8.101",
                name: "192.168.8.101",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "6d4cac84-6bca-47b2-b956-baf9914c5637",
                nodeId: 0,
                ipAddress: "192.168.8.130",
                name: "192.168.8.130",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "17d31e12-e334-4814-afc2-54e58a65b4b8",
                nodeId: 0,
                ipAddress: "192.168.8.136",
                name: "192.168.8.136",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "95596a4b-cab8-458c-a02f-677a37e91d91",
                nodeId: 0,
                ipAddress: "192.168.8.138",
                name: "192.168.8.138",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "cbd50148-2537-4b03-bef2-4c1cd9585550",
                nodeId: 0,
                ipAddress: "192.168.8.213",
                name: "192.168.8.213",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "f6bb5a17-fc40-434c-aa9f-b41e9b7adc36",
                nodeId: 0,
                ipAddress: "192.168.8.254",
                name: "192.168.8.254",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "8f5e8d48-904b-4a08-8b8c-b33a2aa4b461",
                nodeId: 0,
                ipAddress: "192.168.8.27",
                name: "192.168.8.27",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "406fbda5-0a4d-4610-964c-b8abe9984d23",
                nodeId: 0,
                ipAddress: "192.168.8.30",
                name: "192.168.8.30",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "ac2b275f-aec1-4913-86ea-8b959a20cd5f",
                nodeId: 0,
                ipAddress: "192.168.8.129",
                name: "192.168.8.129",
                category: "un-identified",
                status: false,
                location: "5G",
                cellId: null,
                children: [],
              },
            ],
          },
          {
            level: 3,
            id: "18e547a0-e1b3-4615-9da6-9240fa6f6161",
            nodeId: 0,
            ipAddress: "",
            name: "Hardware Block",
            category: "Location",
            status: true,
            location: "HW",
            cellId: null,
            children: [
              {
                level: 4,
                id: "c957e639-17b3-4ea1-bd40-4272b96cc56f",
                nodeId: 0,
                ipAddress: "192.168.80.2",
                name: "192.168.80.2",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "3a465479-f485-4189-8505-b6c457ecff4a",
                nodeId: 0,
                ipAddress: "192.168.80.24",
                name: "192.168.80.24",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "04cdb094-0131-4ebe-8095-5eab0c42c9d6",
                nodeId: 0,
                ipAddress: "192.168.80.25",
                name: "192.168.80.25",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "18360bd4-a19a-407d-bdf6-f380bb70e16f",
                nodeId: 0,
                ipAddress: "192.168.80.29",
                name: "192.168.80.29",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "4a8a7be1-4e57-4e45-be40-3e899f62986a",
                nodeId: 0,
                ipAddress: "192.168.80.31",
                name: "192.168.80.31",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "f535b192-2614-459c-9cd4-372050d187c1",
                nodeId: 0,
                ipAddress: "192.168.80.42",
                name: "192.168.80.42",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "cf57c49d-6b59-4269-b1a5-322683d70dec",
                nodeId: 0,
                ipAddress: "192.168.80.87",
                name: "192.168.80.87",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "2e4aeb2f-a72b-404c-9073-3e4a3a5e970e",
                nodeId: 0,
                ipAddress: "192.168.80.100",
                name: "192.168.80.100",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "24f6afb0-2769-4a9a-80c1-e250c4ca4d16",
                nodeId: 0,
                ipAddress: "192.168.80.105",
                name: "192.168.80.105",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "023b29b9-cbdf-4c0f-8e4c-6dc429be4caa",
                nodeId: 0,
                ipAddress: "192.168.80.238",
                name: "192.168.80.238",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "922d39bb-7ee5-44b7-8391-bc0324e546c9",
                nodeId: 0,
                ipAddress: "192.168.80.254",
                name: "192.168.80.254",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "3f3532dd-53aa-4fc6-93e4-9e19d5e6e52b",
                nodeId: 0,
                ipAddress: "192.168.80.98",
                name: "192.168.80.98",
                category: "un-identified",
                status: false,
                location: "HW",
                cellId: null,
                children: [],
              },
            ],
          },
          {
            level: 3,
            id: "f625065e-3815-4c37-937a-febb482f0223",
            nodeId: 0,
            ipAddress: "",
            name: "Server Room",
            category: "Location",
            status: true,
            location: "SERVER-ROOM",
            cellId: null,
            children: [
              {
                level: 4,
                id: "76c898a6-deb3-41f7-8602-4ad03817533b",
                nodeId: 0,
                ipAddress: "192.168.20.1",
                name: "192.168.20.1",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "623536aa-3f51-4c44-9dcb-7e1a0303f346",
                nodeId: 0,
                ipAddress: "192.168.20.5",
                name: "192.168.20.5",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "0eddf363-0c02-4b88-ab13-ae252e9e5bef",
                nodeId: 0,
                ipAddress: "192.168.20.6",
                name: "192.168.20.6",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "306dd90f-81b3-42ff-b514-4d2d887579b7",
                nodeId: 0,
                ipAddress: "192.168.20.7",
                name: "192.168.20.7",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "73927c59-7ff5-4f47-9143-23027b78f5f9",
                nodeId: 0,
                ipAddress: "192.168.20.8",
                name: "192.168.20.8",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "14c39069-92a1-4124-b460-785d531b1617",
                nodeId: 0,
                ipAddress: "192.168.20.9",
                name: "192.168.20.9",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "93fba6e1-6252-4169-8a60-e4880c58360b",
                nodeId: 0,
                ipAddress: "192.168.20.10",
                name: "192.168.20.10",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "23d95315-a298-493b-ab3c-2ffcfeecb326",
                nodeId: 0,
                ipAddress: "192.168.20.14",
                name: "192.168.20.14",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "068539de-a13c-45c5-b2ca-6e90a09e935d",
                nodeId: 0,
                ipAddress: "192.168.20.16",
                name: "192.168.20.16",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "5c93b473-4eaf-4284-900a-ff0c55714488",
                nodeId: 0,
                ipAddress: "192.168.20.24",
                name: "192.168.20.24",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "9ab9da23-a314-44ca-827c-75937f46ef66",
                nodeId: 0,
                ipAddress: "192.168.20.25",
                name: "192.168.20.25",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "f07faa7a-890a-47ee-a0b8-45df5494c2ac",
                nodeId: 0,
                ipAddress: "192.168.20.26",
                name: "192.168.20.26",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "abaa554a-b94a-485f-8417-82f6bfc59d54",
                nodeId: 0,
                ipAddress: "192.168.20.28",
                name: "192.168.20.28",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "83bcd3cf-8a00-40c9-8384-6bd260c97635",
                nodeId: 0,
                ipAddress: "192.168.20.30",
                name: "192.168.20.30",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "7b3e7ee4-4fb3-432b-afe5-2bf6f54625f4",
                nodeId: 0,
                ipAddress: "192.168.20.40",
                name: "192.168.20.40",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "8d1c9adc-e5c2-4041-a8fe-834720e23d15",
                nodeId: 0,
                ipAddress: "192.168.20.47",
                name: "192.168.20.47",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1fb85dc7-c0fb-4f90-b627-7e3f08ad96f1",
                nodeId: 0,
                ipAddress: "192.168.20.48",
                name: "192.168.20.48",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "986f1868-04db-4f55-a857-d8e60b925b9f",
                nodeId: 0,
                ipAddress: "192.168.20.50",
                name: "192.168.20.50",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "73aaa5df-cebb-48d3-af71-a96a9ad37deb",
                nodeId: 0,
                ipAddress: "192.168.20.52",
                name: "192.168.20.52",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "4aeb5bb0-a046-4e66-9598-9889e355e688",
                nodeId: 0,
                ipAddress: "192.168.20.56",
                name: "192.168.20.56",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "8ead3491-0154-4190-a029-ef39ad1ecef5",
                nodeId: 0,
                ipAddress: "192.168.20.57",
                name: "192.168.20.57",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "e0f3d03b-fca7-40dc-a417-8f11230f80fc",
                nodeId: 0,
                ipAddress: "192.168.20.58",
                name: "192.168.20.58",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "be046928-fd23-4493-b1a1-12896f863e78",
                nodeId: 0,
                ipAddress: "192.168.20.60",
                name: "192.168.20.60",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "f1c2397d-8f8e-4f0d-8451-913b644e0bff",
                nodeId: 0,
                ipAddress: "192.168.20.76",
                name: "192.168.20.76",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "40cf4f09-cd2a-4279-9938-cd027ddea16d",
                nodeId: 0,
                ipAddress: "192.168.20.77",
                name: "192.168.20.77",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1325ef48-ee7b-45e7-bc39-9df715dcc179",
                nodeId: 0,
                ipAddress: "192.168.20.78",
                name: "192.168.20.78",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "848defda-9099-412f-84ce-95a5301e0dee",
                nodeId: 0,
                ipAddress: "192.168.20.88",
                name: "192.168.20.88",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "90d12f57-7e69-402a-b524-5672823ff01b",
                nodeId: 0,
                ipAddress: "192.168.20.109",
                name: "192.168.20.109",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1de6a3ea-ac1f-4544-acd0-1960f87356d4",
                nodeId: 0,
                ipAddress: "192.168.20.118",
                name: "192.168.20.118",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "eb87af57-ec9f-4208-944e-24bd908f0016",
                nodeId: 0,
                ipAddress: "192.168.20.129",
                name: "192.168.20.129",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "7dc6fd0c-97ad-41a2-b33f-2af74610696e",
                nodeId: 0,
                ipAddress: "192.168.20.134",
                name: "192.168.20.134",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "2007caa2-d5ab-426c-b49a-fb52ba9839ca",
                nodeId: 0,
                ipAddress: "192.168.20.175",
                name: "192.168.20.175",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "24852dba-47ff-4991-b3d6-89d3b5a9a1fb",
                nodeId: 0,
                ipAddress: "192.168.20.178",
                name: "192.168.20.178",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "4a4858fc-78cf-49cd-a1fa-240ec718d6a0",
                nodeId: 0,
                ipAddress: "192.168.20.179",
                name: "192.168.20.179",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "aee337c2-4753-46ad-99fd-b2c524568e1e",
                nodeId: 0,
                ipAddress: "192.168.20.180",
                name: "192.168.20.180",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "9f9563ef-a17b-45e5-b9ab-f1ad07a7b703",
                nodeId: 0,
                ipAddress: "192.168.20.181",
                name: "192.168.20.181",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "4d9306c0-b995-473f-ba07-5809287b0366",
                nodeId: 0,
                ipAddress: "192.168.20.183",
                name: "192.168.20.183",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1330db20-f509-42ea-a829-f2617c9e485f",
                nodeId: 0,
                ipAddress: "192.168.20.185",
                name: "192.168.20.185",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "39522197-cd64-484c-b203-7ca20bea2e76",
                nodeId: 0,
                ipAddress: "192.168.20.186",
                name: "192.168.20.186",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "5e9ab4e6-3d7e-4f0c-ba83-b44796e587ec",
                nodeId: 0,
                ipAddress: "192.168.20.190",
                name: "192.168.20.190",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "730ef597-ec92-415a-bf30-f229df90183e",
                nodeId: 0,
                ipAddress: "192.168.20.195",
                name: "192.168.20.195",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "b7d04382-f08f-4a4f-997f-fddab21f0ceb",
                nodeId: 0,
                ipAddress: "192.168.20.211",
                name: "192.168.20.211",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "d3d47fcd-ee1d-4feb-8e02-d86c50b9a00e",
                nodeId: 0,
                ipAddress: "192.168.20.232",
                name: "192.168.20.232",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "a196f648-76d9-4e0f-b93a-5e6bdc76e8e7",
                nodeId: 0,
                ipAddress: "192.168.20.236",
                name: "192.168.20.236",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "01adea5a-b13f-4a0a-9986-33b6da42e821",
                nodeId: 0,
                ipAddress: "192.168.20.240",
                name: "192.168.20.240",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "d3ea29f3-7163-42a2-8462-9ec19bc0b014",
                nodeId: 0,
                ipAddress: "192.168.20.241",
                name: "192.168.20.241",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "08ddc17c-21a0-4cde-ab08-54091ba51226",
                nodeId: 0,
                ipAddress: "192.168.20.242",
                name: "192.168.20.242",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "c90681fa-c3f2-4227-95d5-2e95c0a5cfbf",
                nodeId: 0,
                ipAddress: "192.168.20.246",
                name: "192.168.20.246",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "bd827042-4aa1-4082-90c4-b5519a5c1a64",
                nodeId: 0,
                ipAddress: "192.168.20.248",
                name: "192.168.20.248",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "299cd146-903e-472b-a914-ad940d3f318e",
                nodeId: 0,
                ipAddress: "192.168.20.249",
                name: "192.168.20.249",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "9fd4b13e-5c62-4f03-8993-50ecd1aa60a5",
                nodeId: 0,
                ipAddress: "192.168.20.251",
                name: "192.168.20.251",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1a11a948-1c41-476e-93b7-970dede492cf",
                nodeId: 0,
                ipAddress: "192.168.20.252",
                name: "192.168.20.252",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "7090d81e-96e2-413a-83da-5193d0b6d05d",
                nodeId: 0,
                ipAddress: "192.168.20.253",
                name: "192.168.20.253",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "6e4b173c-5fde-4c95-8b67-820fdef5cee3",
                nodeId: 0,
                ipAddress: "192.168.20.110",
                name: "192.168.20.110",
                category: "un-identified",
                status: false,
                location: "SERVER-ROOM",
                cellId: null,
                children: [],
              },
            ],
          },
          {
            level: 3,
            id: "9cf2e8da-0445-4e9e-b466-9ea5660feb77",
            nodeId: 0,
            ipAddress: "",
            name: "Dev Block",
            category: "Location",
            status: true,
            location: "DEV",
            cellId: null,
            children: [
              {
                level: 4,
                id: "73a45609-4ea5-488c-bfba-1ab1311b3016",
                nodeId: 0,
                ipAddress: "192.168.10.10",
                name: "192.168.10.10",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "e745e0e7-5287-473a-9555-27d0529f95be",
                nodeId: 0,
                ipAddress: "192.168.10.15",
                name: "192.168.10.15",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "761909f3-e822-45b7-b940-68d4235774db",
                nodeId: 0,
                ipAddress: "192.168.10.20",
                name: "192.168.10.20",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "c75042da-ede2-4152-8f7c-1db2a672016f",
                nodeId: 0,
                ipAddress: "192.168.10.26",
                name: "192.168.10.26",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "478e64d2-3404-4c94-8e0b-5d3e2f1096d0",
                nodeId: 0,
                ipAddress: "192.168.10.101",
                name: "192.168.10.101",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "d2c902e4-1904-4671-b33f-47c72b406f9e",
                nodeId: 0,
                ipAddress: "192.168.10.102",
                name: "192.168.10.102",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1ab1daba-13de-4f8b-8b11-7003f1238d2a",
                nodeId: 0,
                ipAddress: "192.168.10.103",
                name: "192.168.10.103",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "7522df5d-c11e-4aa9-be91-25e84aea61bf",
                nodeId: 0,
                ipAddress: "192.168.10.105",
                name: "192.168.10.105",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "a7cc13ea-ec6e-4fe7-853b-cecdd1f4da98",
                nodeId: 0,
                ipAddress: "192.168.10.106",
                name: "192.168.10.106",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "277017b9-259f-43fe-82d2-07c20df21cbf",
                nodeId: 0,
                ipAddress: "192.168.10.107",
                name: "192.168.10.107",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "702c24d6-9414-46b0-8b7d-3a49bf2957d7",
                nodeId: 0,
                ipAddress: "192.168.10.108",
                name: "192.168.10.108",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "c84a7ed8-83c2-4119-b4c5-3f1ca53db59f",
                nodeId: 0,
                ipAddress: "192.168.10.109",
                name: "192.168.10.109",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "55613a58-dc9c-45fe-a3bc-10277b939b0b",
                nodeId: 0,
                ipAddress: "192.168.10.110",
                name: "192.168.10.110",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "2876e2ad-f18d-4604-a20a-cb21eabf73ed",
                nodeId: 0,
                ipAddress: "192.168.10.254",
                name: "192.168.10.254",
                category: "un-identified",
                status: false,
                location: "DEV",
                cellId: null,
                children: [],
              },
            ],
          },
          {
            level: 3,
            id: "748f2917-61a9-4a9c-951c-ca8e0e589345",
            nodeId: 0,
            ipAddress: "",
            name: "Testing Team",
            category: "Location",
            status: true,
            location: "TEST",
            cellId: null,
            children: [
              {
                level: 4,
                id: "5705b57d-d7d4-4cf4-b75f-5858e28709dd",
                nodeId: 0,
                ipAddress: "192.168.250.10",
                name: "192.168.250.10",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "24c025d1-78b6-44eb-9c3f-9c86c039ee69",
                nodeId: 0,
                ipAddress: "192.168.250.31",
                name: "192.168.250.31",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "77a86c73-bee8-4b70-be05-86b5ba5d04ef",
                nodeId: 0,
                ipAddress: "192.168.250.32",
                name: "192.168.250.32",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "ad5161df-5da1-4962-a6d7-76a0779ba05f",
                nodeId: 0,
                ipAddress: "192.168.250.33",
                name: "192.168.250.33",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "2e6ec92e-1943-4bb3-9a16-a07d993577be",
                nodeId: 0,
                ipAddress: "192.168.250.35",
                name: "192.168.250.35",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "ac7cda0e-6ea8-46c2-ad73-6cd085b1d13b",
                nodeId: 0,
                ipAddress: "192.168.250.36",
                name: "192.168.250.36",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "d85a40bc-4234-4ef2-9322-827b9a6b50e2",
                nodeId: 0,
                ipAddress: "192.168.250.37",
                name: "192.168.250.37",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "c5a89d6f-82bc-4899-a3cb-6b217735b01b",
                nodeId: 0,
                ipAddress: "192.168.250.40",
                name: "192.168.250.40",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "3e374364-b457-471f-9c5f-1a930d0d1b7e",
                nodeId: 0,
                ipAddress: "192.168.250.120",
                name: "192.168.250.120",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "f864cfa7-e6c5-4fb6-8dd5-8c053ee867c2",
                nodeId: 0,
                ipAddress: "192.168.250.201",
                name: "192.168.250.201",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "a9e2c30b-a33c-4e93-80b3-67a47343ca1d",
                nodeId: 0,
                ipAddress: "192.168.250.254",
                name: "192.168.250.254",
                category: "un-identified",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "0b8dc438-9a2c-454c-bd81-7ae17830dceb",
                nodeId: 0,
                ipAddress: "192.168.250.1",
                name: "192.168.250.1",
                category: "PC",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "541c2a8e-83dc-4b50-ab24-f2293952ee6a",
                nodeId: 0,
                ipAddress: "192.168.250.8",
                name: "192.168.250.8",
                category: "L3",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "eb16c9e2-b6a7-4db1-a17b-76e8ebcb1b4e",
                nodeId: 0,
                ipAddress: "192.168.250.89",
                name: "192.168.250.89",
                category: "IP6LP",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "5d392fa3-f0d0-42a6-bee7-2e49feadb109",
                nodeId: 0,
                ipAddress: "192.168.250.166",
                name: "192.168.250.166",
                category: "IP6LP",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "747110d8-8719-4549-8b2a-37c11018b6ba",
                nodeId: 0,
                ipAddress: "192.168.250.182",
                name: "192.168.250.182",
                category: "ICC3",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "78abe179-c778-4e7d-978e-0d63dff9e6c3",
                nodeId: 0,
                ipAddress: "192.168.250.191",
                name: "192.168.250.191",
                category: "CALL-SERVER",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "f13cc661-b50c-4277-8d93-7a9e680001ab",
                nodeId: 0,
                ipAddress: "192.168.250.192",
                name: "192.168.250.192",
                category: "CALL-SERVER",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "73e3ae51-178d-43e6-bb56-7571ed4ad980",
                nodeId: 0,
                ipAddress: "192.168.250.193",
                name: "192.168.250.193",
                category: "CALL-SERVER",
                status: false,
                location: "TEST",
                cellId: null,
                children: [],
              },
            ],
          },
          {
            level: 3,
            id: "daac981d-5715-417a-af6a-83c9e05b8690",
            nodeId: 0,
            ipAddress: "",
            name: "Corporate",
            category: "Location",
            status: true,
            location: "CORP",
            cellId: null,
            children: [
              {
                level: 4,
                id: "b12a5b43-63bc-4f24-9475-ce529c33ed57",
                nodeId: 0,
                ipAddress: "192.168.70.44",
                name: "192.168.70.44",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "98e4850e-e498-4e32-b7aa-18d259ae55b6",
                nodeId: 0,
                ipAddress: "192.168.70.50",
                name: "192.168.70.50",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "de43ebf0-9088-4345-b0bf-c96e005ef1d7",
                nodeId: 0,
                ipAddress: "192.168.70.55",
                name: "192.168.70.55",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "b8d5b4e0-cbc5-4a16-8aa7-e6fce112eeb5",
                nodeId: 0,
                ipAddress: "192.168.70.103",
                name: "192.168.70.103",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "b33f2334-6d44-4dc3-9c0d-ed62a6efe477",
                nodeId: 0,
                ipAddress: "192.168.70.104",
                name: "192.168.70.104",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "49758585-6c6c-4084-8b04-74da45a15e92",
                nodeId: 0,
                ipAddress: "192.168.70.114",
                name: "192.168.70.114",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "be346e98-28d5-4b56-8cb1-dc0e359f24b9",
                nodeId: 0,
                ipAddress: "192.168.70.119",
                name: "192.168.70.119",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "a7c94ee4-0288-4e5f-b793-8d413febe645",
                nodeId: 0,
                ipAddress: "192.168.70.124",
                name: "192.168.70.124",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "1f91aefc-822f-4e80-aafb-bba4bd34cea0",
                nodeId: 0,
                ipAddress: "192.168.70.146",
                name: "192.168.70.146",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "ac300302-cbdd-418e-875e-355f19e60ecf",
                nodeId: 0,
                ipAddress: "192.168.70.192",
                name: "192.168.70.192",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "a13bfdad-8c90-4735-a70a-a7de955de370",
                nodeId: 0,
                ipAddress: "192.168.70.206",
                name: "192.168.70.206",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "a4ee50d2-8a55-4ce1-881b-c5c024448c69",
                nodeId: 0,
                ipAddress: "192.168.70.214",
                name: "192.168.70.214",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "2719cb42-3c69-42d8-a305-a8afd2d40f20",
                nodeId: 0,
                ipAddress: "192.168.70.220",
                name: "192.168.70.220",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "b776d1ce-ac94-4d68-bf3a-8273686c3632",
                nodeId: 0,
                ipAddress: "192.168.70.246",
                name: "192.168.70.246",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "c004ee92-4958-408f-adac-148fd2d95ddd",
                nodeId: 0,
                ipAddress: "192.168.70.250",
                name: "192.168.70.250",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
              {
                level: 4,
                id: "0eb75957-bc6e-447b-ab93-58b2cedb6f00",
                nodeId: 0,
                ipAddress: "192.168.70.254",
                name: "192.168.70.254",
                category: "un-identified",
                status: false,
                location: "CORP",
                cellId: null,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default TOPOLOGY_DUMMY_DATA;
