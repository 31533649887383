const ASSETS_CATEGORIES = {
  LOCATION: "Location",
  L2: "L2",
  L3: "L3",
  L3_SWITCH: "L3-Switch",
  IP_PHONE: "IP Phone",
  IP2LP: "IP2LP",
  IP2LG: "IP2LG",
  IP6LP: "IP6LP",
  VIP: "VIP",
  ICC3: "ICC3",
  ICC4: "ICC4",
  CALL_SERVER: "CALL-SERVER",
  SERVER: "Server",
  FEATURE_SERVER: "FEATURE-SERVER",
  ENODEB: "eNodeB",
  IMSI: "IMSI",
  HELP_POINT: "Help Point",
  UN_IDENTIFIED: "un-identified",
  ROVER: "Rover",
  PC: "PC",
  LAPTOP: "LAPTOP",
  AHD: "AHD",
  VHD: "VHD",
  GW: "GW",
  TRUNK_GATEWAY: "TRUNK-GATEWAY",
};

export default ASSETS_CATEGORIES;
