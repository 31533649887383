import getAllSidebarsBreakpointSum from "./getAllSidebarsBreakpointSum";

export default function getInnerBodyBreakpoints(layout) {
  const leftSidebarsBreakpointSum = getAllSidebarsBreakpointSum(layout.leftSidebar);
  const rightSidebarsBreakpointSum = getAllSidebarsBreakpointSum(layout.rightSidebar);

  const bothSidebarsBreakpointSum = {
    xl: leftSidebarsBreakpointSum.xl + rightSidebarsBreakpointSum.xl,
    lg: leftSidebarsBreakpointSum.lg + rightSidebarsBreakpointSum.lg,
    md: leftSidebarsBreakpointSum.md + rightSidebarsBreakpointSum.md,
    sm: leftSidebarsBreakpointSum.sm + rightSidebarsBreakpointSum.sm,
    xs: leftSidebarsBreakpointSum.xs + rightSidebarsBreakpointSum.xs,
  };

  return {
    xl: 12 - bothSidebarsBreakpointSum.xl <= 0 ? 12 : 12 - bothSidebarsBreakpointSum.xl,
    lg: 12 - bothSidebarsBreakpointSum.lg <= 0 ? 12 : 12 - bothSidebarsBreakpointSum.lg,
    md: 12 - bothSidebarsBreakpointSum.md <= 0 ? 12 : 12 - bothSidebarsBreakpointSum.md,
    sm: 12 - bothSidebarsBreakpointSum.sm <= 0 ? 12 : 12 - bothSidebarsBreakpointSum.sm,
    xs: 12 - bothSidebarsBreakpointSum.xs <= 0 ? 12 : 12 - bothSidebarsBreakpointSum.xs,
  };
}
