import { forwardRef, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function LabelPopUp({ location, labelPopUpOpen, handleLabelPopupClose, setLabelPopUpOpen }) {
  const initialLabelConfig = {
    text: location.configuration.labelText,
    orientation: location.configuration.labelOrientation,
    fontSize: location.configuration.labelFontSize,
    showLabel: location.configuration.showLabel,
  };

  const [labelConfig, setLabelConfig] = useState(initialLabelConfig);
  const { darkMode } = useMaterialUIController()[0];

  const handleLabelText = (event) =>
    setLabelConfig((prevLabelConfig) => ({
      ...prevLabelConfig,
      text: event.target.value,
    }));

  const handleLabelFontSizeChange = (event) =>
    setLabelConfig((prevLabelConfig) => ({
      ...prevLabelConfig,
      fontSize: event.target.value,
    }));

  const handleShowLabelChange = (event) => {
    setLabelConfig((prevLabelConfig) => ({
      ...prevLabelConfig,
      text: event.target.checked ? "" : location.name,
    }));
  };

  return (
    <Dialog
      open={labelPopUpOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setLabelConfig(initialLabelConfig);
        setLabelPopUpOpen(false);
      }}
      fullWidth
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: darkMode ? theme.palette.background.card : theme.palette.white.main,
        })}
      >
        <FormControl>
          <FormLabel
            sx={(theme) => ({
              color: theme.palette.text.main,
            })}
          >
            Label orientation
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel
              checked={labelConfig.orientation === "horizontal"}
              control={<Radio />}
              onChange={() => {
                setLabelConfig((prevLabelConfig) => ({
                  ...prevLabelConfig,
                  orientation: "horizontal",
                }));
              }}
              label="Horizontal"
            />
            <FormControlLabel
              checked={labelConfig.orientation === "vertical"}
              control={<Radio />}
              onChange={() => {
                setLabelConfig((prevLabelConfig) => ({
                  ...prevLabelConfig,
                  orientation: "vertical",
                }));
              }}
              label="Vertical"
            />
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">Font size</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={labelConfig.fontSize}
                onChange={handleLabelFontSizeChange}
                label="Font size"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
          </RadioGroup>
        </FormControl>
        <TextField
          variant="filled"
          label="Enter a label"
          value={labelConfig.text}
          onChange={handleLabelText}
          fullWidth
          sx={(theme) => ({
            backgroundColor: darkMode ? theme.palette.dark.main : theme.palette.white.main,
            "& input": {
              color: darkMode ? theme.palette.text.main : "",
            },
          })}
        />
        <FormControlLabel
          control={<Checkbox checked={labelConfig.text === ""} onChange={handleShowLabelChange} />}
          label="No label"
        />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          marginTop: "-1.5em",
          backgroundColor: darkMode ? theme.palette.background.card : theme.palette.white.main,
        })}
      >
        <MDButton
          variant="gradient"
          color="success"
          onClick={() => handleLabelPopupClose(labelConfig, location)}
        >
          Update
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default LabelPopUp;
