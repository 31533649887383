// Template components
import MDSnackbar from "components/MDSnackbar";

function SaveMessage({ showSaveMessage, toggleSaveMessage }) {
  return (
    <MDSnackbar
      color="success"
      icon="done"
      title="Done"
      content="Layout saved successfully!"
      dateTime=""
      open={showSaveMessage}
      close={toggleSaveMessage}
    />
  );
}

export default SaveMessage;
