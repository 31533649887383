// Third party library components
import { ResponsiveCalendar } from "@nivo/calendar";

// Template custom hooks
import { useMaterialUIController } from "context";

function NivoCalendar({ calendarData, startDate, endDate, widgetHeight }) {
  const { darkMode } = useMaterialUIController()[0];

  return (
    <ResponsiveCalendar
      theme={{ textColor: darkMode ? "#ffffffcc" : "#344767" }}
      data={calendarData}
      from={startDate.split("-")[0]}
      to={endDate.split("-")[0]}
      emptyColor={darkMode ? "#202940" : "#eeeeee"}
      colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
      dayBorderWidth={1}
      dayBorderColor="#ffffffcc"
      monthBorderWidth={1}
      monthBorderColor="#ffffffcc"
      monthSpacing={10}
      height={parseInt(widgetHeight, 10) * 9}
      yearSpacing={30}
      margin={{
        top: 30,
        right: 60,
        bottom: 20,
        left: 60,
      }}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          translateY: -100,
          translateX: -70,
          itemCount: 4,
          itemWidth: 40,
          itemHeight: 30,
          itemDirection: "left-to-right",
        },
      ]}
    />
  );
}

export default NivoCalendar;
