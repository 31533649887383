import { useMemo } from "react";

// NMS dashboard RTK-query hooks
import { useGetWidgetConfigQuery } from "features/apiSlice";

// NMS dashboard configs
import { DUMMY_WIDGET_ID, WIDGET_TYPES } from "../../configs/widgetsConfigs";
import { CONTAINER_ORIENTATION } from "../../configs/containersConfigs";

// NMS dashboard logs
import requestErrorLog from "../../logs/error";

// NMS dashboard components
import Counter from "../widgetTypes/Counter";
import Calendar from "../widgetTypes/Calendar";
import Roundchart from "../widgetTypes/charts/Roundchart";
import Barchart from "../widgetTypes/charts/Barchart";
import Linechart from "../widgetTypes/charts/Linechart";
import ServerTable from "../widgetTypes/tables/ServerTable";
import UITable from "../widgetTypes/tables/UITable";
import Iframe from "../widgetTypes/Iframe";
import Gauge from "../widgetTypes/Gauge";
import SmartTopology from "../widgetTypes/SmartTopology";
import GraphTopology from "../widgetTypes/GraphTopology";
import Topology from "../widgetTypes/Topology";
import SmartAsset from "../widgetTypes/SmartAsset";

function Widget({
  index,
  widget,
  masterContainerId,
  childContainer,
  isEditMode,
  isAdmin,
  handleSwitchWidgetType,
  handleDeleteWidget,
  handleBreadcrumbs,
  containerOrientation,
}) {
  // Fetching widget configuration
  const { data, error, isError } = useGetWidgetConfigQuery(`/dashboard/widget/${widget.id}`, {
    skip: widget.id === DUMMY_WIDGET_ID,
  });

  // If error, return error message
  useMemo(() => requestErrorLog(isError, error, widget.id), [error]);

  // If no error, return widget
  if (data || widget.id === DUMMY_WIDGET_ID) {
    const widgetType = widget.widgetType || data.widgetType;

    let renderWidget = null;

    switch (widgetType) {
      case WIDGET_TYPES.piechart:
      case WIDGET_TYPES.doughnutchart:
        renderWidget = (
          <Roundchart
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={
              containerOrientation === CONTAINER_ORIENTATION.horizontal
                ? childContainer.height
                : childContainer.defaultWidgetHeight || childContainer.height
            }
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleSwitchWidgetType={handleSwitchWidgetType}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.barchart:
        renderWidget = (
          <Barchart
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={
              containerOrientation === CONTAINER_ORIENTATION.horizontal
                ? childContainer.height
                : childContainer.defaultWidgetHeight || childContainer.height
            }
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleSwitchWidgetType={handleSwitchWidgetType}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.linechart:
        renderWidget = (
          <Linechart
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={
              containerOrientation === CONTAINER_ORIENTATION.horizontal
                ? childContainer.height
                : childContainer.defaultWidgetHeight || childContainer.height
            }
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleSwitchWidgetType={handleSwitchWidgetType}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.counter:
        renderWidget = (
          <Counter
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.calendar:
        renderWidget = (
          <Calendar
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.serverTable:
        renderWidget = (
          <ServerTable
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.UITable:
        renderWidget = (
          <UITable
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            handleBreadcrumbs={handleBreadcrumbs}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.iFrame:
        renderWidget = (
          <Iframe
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.gauge:
        renderWidget = (
          <Gauge
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            containerOrientation={containerOrientation}
          />
        );
        break;
      case WIDGET_TYPES.smartTopology:
        renderWidget = (
          <SmartTopology
            index={index}
            widgetId={widget.id}
            widgetType={widgetType}
            widgetConfig={data}
            widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
            masterContainerId={masterContainerId}
            childContainerId={childContainer.celId}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            handleDeleteWidget={handleDeleteWidget}
            containerOrientation={containerOrientation}
          />
        );
        break;
      default:
        break;
    }

    return renderWidget;
    // if (finalWidgetType === WIDGET_TYPES.graphTopology)
    //   return (
    //     <GraphTopology
    //       index={index}
    //       widgetConfig={data}
    //       widgetId={widget.id}
    //       widgetType={finalWidgetType}
    //       widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
    //       masterContainerId={masterContainerId}
    //       childContainerId={childContainer.celId}
    //       isEditMode={isEditMode}
    //       isAdmin={isAdmin}
    //       handleDeleteWidget={handleDeleteWidget}
    //       containerType={containerType}
    //     />
    //   );
    // if (finalWidgetType === WIDGET_TYPES.topology)
    //   return (
    //     <Topology
    //       index={index}
    //       widgetConfig={data}
    //       widgetId={widget.id}
    //       widgetType={finalWidgetType}
    //       widgetHeight={childContainer.defaultWidgetHeight || childContainer.height}
    //       masterContainerId={masterContainerId}
    //       childContainerId={childContainer.celId}
    //       isEditMode={isEditMode}
    //       isAdmin={isAdmin}
    //       handleDeleteWidget={handleDeleteWidget}
    //       containerType={containerType}
    //     />
    //   );
    // }
  }
  return null;
}

export default Widget;
