import ACTION_MODES from "../configs/actionConfigs";

export default function chartsActionHandle(
  element,
  actions,
  chartLabels,
  setPopUpOpen,
  setPopUpDashboardId,
  handleBreadcrumbs
) {
  const targetAction = actions.filter(
    (action) => action.value === chartLabels[element[0].index]
  )[0];

  if (targetAction && targetAction.targetWidgetId && targetAction.mode === ACTION_MODES.navigate)
    handleBreadcrumbs(targetAction.targetWidgetId, targetAction.type);
  else if (
    targetAction &&
    targetAction.targetWidgetId &&
    targetAction.mode === ACTION_MODES.popupTable
  ) {
    setPopUpDashboardId(targetAction.targetWidgetId);
    setPopUpOpen(true);
  }
}
