import { v4 as uuidV4 } from "uuid";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
// import DashboardTabs from "layouts/react-dyotis-dashboard-tabs/DashboardTabs.js";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import NMSDashboard from "layouts/react-dyotis-dashboard/NMSDashboard";
import dyotisLogo from "./assets/images/dyotis/final-dyotis-logo.jpg";

const routes = [
  {
    type: "organization",
    name: "WaveSync",
    key: "Dyotis Technologies",
    icon: <MDAvatar src={dyotisLogo} alt="dyotisLogo" size="sm" />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-0" },

  // Dyotis NMS Routes
  { type: "title", title: "Dyotis NMS", key: "title-dyotis" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dyotisNMS/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: (
      <DashboardLayout key={uuidV4()}>
        <NMSDashboard id="0" username="nkhanna" isAdmin dashboardType="dashboard" />
      </DashboardLayout>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Graph Topology",
    key: "graphTopology",
    route: "/dyotisNMS/GraphTopology",
    icon: <Icon fontSize="medium">hub</Icon>,
    component: (
      <DashboardLayout key={uuidV4()}>
        <NMSDashboard id="13" username="nkhanna" isAdmin dashboardType="GraphTopology" />
      </DashboardLayout>
    ),
    noCollapse: true,
  },
];

export default routes;
