// NMS dashboard utilities
import chartsActionHandle from "../../../../../utils/chartsActionHandle";

function configs(
  legendField,
  valueFieldDataType,
  labels,
  datasets,
  actions,
  setPopUpOpen,
  setPopUpDashboardId,
  handleBreadcrumbs
) {
  return {
    linechartData: {
      labels,
      datasets,
    },
    linechartOptions: {
      onClick: (event, element) =>
        chartsActionHandle(
          element,
          actions,
          labels,
          setPopUpOpen,
          setPopUpDashboardId,
          handleBreadcrumbs
        ),
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "#c1c4ce5c",
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 5],
            color: "#c1c4ce5c",
          },
          ticks: {
            display: true,
            color: "#9ca2b7",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            maxRotation: legendField === "eventEpoch" || valueFieldDataType === "epoch" ? 0 : 32,
          },
        },
      },
    },
  };
}

export default configs;
