export default function requestErrorLog(isError, error, widgetId) {
  if (isError) {
    if (error.name === "AbortError") {
      console.log(
        `Request timed out while fetching the data of widget ID - ${widgetId}. Please try again later.`
      );
      console.log("Please contact the server administrator");
      return;
    }
    console.log(`Server responded with ${error.status || error} status.`);
    console.log(`Unable to fetch data for widget ID: ${widgetId}`);
    console.log("Please contact the server administrator");
  }
}
