// Template assets
import colors from "assets/theme-dark/base/colors";

export default function getGaugeNeedleColor(needleValue, min, thresholdLower, thresholdUpper) {
  let needleColor = null;

  if (thresholdLower && thresholdUpper) {
    if (needleValue >= min && needleValue <= thresholdLower) needleColor = colors.success.main;
    else if (needleValue > thresholdLower && needleValue <= thresholdUpper)
      needleColor = colors.warning.main;
    else needleColor = colors.primary.main;
  } else if (thresholdLower) {
    if (needleValue >= min && needleValue <= thresholdLower) needleColor = colors.success.main;
    else needleColor = colors.primary.main;
  } else if (needleValue >= min && needleValue <= thresholdUpper) needleColor = colors.success.main;
  else needleColor = colors.primary.main;

  return needleColor;
}
