import { useState, useEffect } from "react";
import Draggable from "react-draggable";
// import PopupTopology from "../../graphTopology/PopupTopology";
import { Menu, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import LabelPopUp from "../LabelPopUp";
import Tooltip from "./Tooltip";
import SMART_TOPOLOGY_MARKER_SIZE from "../../../../configs/smartTopologyConfigs";
import SENSOR_CONFIGS from "../../../../configs/sensorConfigs";

function getSubNetworkRoot(subNetwork, path) {
  let subNetworkRoot = null;

  path.forEach((nodeId) => {
    const node = subNetwork.find((nodeDatum) => nodeDatum.id === nodeId);

    if (node) {
      subNetworkRoot = node;
      subNetwork = node.children;
    }
  });

  return subNetworkRoot;
}

function Location({
  isAdmin,
  isEditMode,
  location,
  smartTopologyData,
  aggregatedTree,
  handleLocationDragStop,
  setDroppedLocations,
  setUndroppedLocations,
  handleSaveConfiguration,
}) {
  const [popupTopologyOpen, setPopupTopologyOpen] = useState(false);
  const [subNetworkRoot, setSubNetworkRoot] = useState(null);
  const [popupTopologyNodeColor, setPopupTopologyNodeColor] = useState(null);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const [labelPopUpOpen, setLabelPopUpOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const { active, inactive, path, severity } = aggregatedTree.filter(
    (node) => node.id === location.uuid
  )[0];
  const markerSize = SMART_TOPOLOGY_MARKER_SIZE;

  useEffect(() => {
    setSubNetworkRoot(() => getSubNetworkRoot(smartTopologyData, path));
    setPopupTopologyNodeColor(() => SENSOR_CONFIGS[severity]);
  }, [smartTopologyData]);

  const handlePopupTopologyOpen = () => setPopupTopologyOpen(true);

  const handlePopupTopologyClose = () => setPopupTopologyOpen(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleLabelPopupOpen = () => {
    setLabelPopUpOpen(true);
    setContextMenu(null);
  };

  const handleDeleteLocation = () => {
    const newLocation = {
      id: location.id,
      xcoordinate: "",
      ycoordinate: "",
      configuration: "",
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.filter((targetLocation) => targetLocation.uuid !== newLocation.uuid)
    );
    setUndroppedLocations((prevUndroppedLocations) => [...prevUndroppedLocations, newLocation]);
    setContextMenu(null);
    handleSaveConfiguration(newLocation);
  };

  const handleLabelPopupClose = (labelConfig, labelAssociatedLocation) => {
    const newLocation = {
      ...labelAssociatedLocation,
      configuration: {
        ...labelAssociatedLocation.configuration,
        labelText: labelConfig.text,
        labelOrientation: labelConfig.orientation,
        labelFontSize: labelConfig.fontSize,
      },
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.map((locationData) =>
        locationData.uuid === labelAssociatedLocation.uuid ? newLocation : locationData
      )
    );
    setLabelPopUpOpen(false);
    handleSaveConfiguration(newLocation);
  };

  let indicatorTimeoutId = null;

  return (
    <>
      {isIndicatorVisible && <Tooltip location={location} active={active} inactive={inactive} />}
      <Draggable
        position={{
          x: location.xcoordinate - markerSize / 2,
          y: location.ycoordinate - markerSize / 2,
        }}
        onStop={(e, ui) => handleLocationDragStop(ui.lastX, ui.lastY, location)}
        disabled={!isAdmin || !isEditMode}
      >
        <MDBox
          position="absolute"
          width={`${markerSize}px`}
          height={`${markerSize}px`}
          sx={{
            cursor: "pointer",
            backgroundColor: popupTopologyNodeColor,
            borderRadius: "50%",
          }}
          border="2px solid black"
          onDoubleClick={handlePopupTopologyOpen}
          onMouseEnter={() => {
            if (!isIndicatorVisible)
              indicatorTimeoutId = setTimeout(
                () => setIsIndicatorVisible(!isIndicatorVisible),
                300
              );
          }}
          onMouseLeave={() => {
            clearTimeout(indicatorTimeoutId);
            if (isIndicatorVisible) setIsIndicatorVisible(!isIndicatorVisible);
          }}
          onContextMenu={handleContextMenu}
        />
      </Draggable>
      {isAdmin && isEditMode && (
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
          }
        >
          <MenuItem onClick={handleLabelPopupOpen}>Edit label</MenuItem>
          <MenuItem onClick={handleDeleteLocation}>Delete location</MenuItem>
        </Menu>
      )}
      <LabelPopUp
        location={location}
        labelPopUpOpen={labelPopUpOpen}
        handleLabelPopupClose={handleLabelPopupClose}
        setLabelPopUpOpen={setLabelPopUpOpen}
      />
      {/* {subNetworkRoot && subNetworkRoot.children.length > 0 && (
        <PopupTopology
          nodeDatum={subNetworkRoot}
          categoryData={categoryData}
          flatTopologyData={flatTopologyData}
          threshold={threshold}
          popupTopologyOpen={popupTopologyOpen}
          handlePopupTopologyClose={handlePopupTopologyClose}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
      )} */}
    </>
  );
}

export default Location;
