// MUI components
import { Menu, MenuItem } from "@mui/material";

// Third party utilities
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

// NMS dashboard assets
import coralTelecom from "../../assets/coral-telecom.png";

function ExportPDF({
  exportComponentId,
  exportPDFContextMenu,
  handleExportPDFContextMenuClose,
  name,
}) {
  // Handler for exporting widget to PDF
  const handleExportClick = () => {
    // Select the widget to be exported
    const widget = document.getElementById(exportComponentId);

    // Convert the widget to canvas
    html2canvas(widget).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF();

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Set the logo image
      const logoWidth = 40;
      const logoHeight = 25;
      const logoX = pdfWidth - logoWidth - 14;
      const logoY = 10;
      pdf.addImage(coralTelecom, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Set the heading
      const heading = name;
      const headingFontSize = 18;
      const headingX = 14;
      const headingY = 15;
      pdf.setFontSize(headingFontSize);
      pdf.text(heading, headingX, headingY, { align: "left" });

      // Set the timestamp
      const timestamp = new Date().toLocaleString("en-IN"); // Get current timestamp
      const timestampFontSize = 12;
      const timestampX = 14;
      const timestampY = headingY + headingFontSize;
      pdf.setFontSize(timestampFontSize);
      pdf.text(timestamp, timestampX, timestampY, { align: "left" });

      // Set the widgetimage in the center
      const aspectRatio = canvas.width / canvas.height;
      let imgWidth;
      let imgHeight;
      if (pdfWidth > pdfHeight) {
        imgWidth = pdfHeight * aspectRatio;
        imgHeight = pdfHeight;
      } else {
        imgWidth = pdfWidth;
        imgHeight = pdfWidth / aspectRatio;
      }

      const xPos = (pdfWidth - imgWidth) / 2;
      const yPos = (pdfHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight, "", "FAST");
      pdf.save(`${name}.pdf`);
    });

    handleExportPDFContextMenuClose();
  };

  return (
    <Menu
      open={exportPDFContextMenu !== null}
      onClose={handleExportPDFContextMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        exportPDFContextMenu !== null
          ? { top: exportPDFContextMenu.mouseY, left: exportPDFContextMenu.mouseX }
          : null
      }
    >
      <MenuItem onClick={handleExportClick}>Export to PDF</MenuItem>
    </Menu>
  );
}

export default ExportPDF;
