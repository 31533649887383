export const WIDGET_TYPES = {
  piechart: "pie",
  doughnutchart: "doughnut",
  linechart: "line-chart",
  barchart: "bar",
  calendar: "calendar",
  counter: "counter",
  serverTable: "table",
  UITable: "UITable",
  graphTopology: "graph-topology",
  topology: "topology",
  smartTopology: "smart-topology",
  smartAsset: "smartAsset",
  iFrame: "iFrame",
  gauge: "speedo-meter",
};

export const WIDGET_OPTIONS = {
  calendar: "Calendar",
  counter: "Counter",
  piechart: "Piechart",
  doughnutchart: "Doughnutchart",
  barchart: "Barchart",
  linechart: "Linechart",
  serverTable: "Server table",
  UITable: "UI table",
  graphTopology: "Graph Topology",
  topology: "Network Topology",
  smartTopology: "Smart Topology",
  smartAsset: "Smart Asset",
  iFrame: "iFrame",
  gauge: "Gauge",
};

export const DUMMY_WIDGET_LABELS = {
  primaryLabel: "Dummy Primary Label",
  secondaryLabel: "Widget ID not configured",
};

export const DUMMY_WIDGET_ID = "Dummy Widget ID";

export const WIDGET_LOADING_LABEL = "Loading...";

export const WIDGET_ERROR_LABEL = "Unable to update the widget";

export const WIDGET_EMPTY_LABEL = "";
